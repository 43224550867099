$primary: #5b6af9;
$info: #929cf7;

$container-max-widths: (
  // 540px
  sm: 33.75rem,
  // 720px
  md: 45rem,
  // 960px
  lg: 60rem,
  // 1140px
  xl: 71.25rem,
  // 1440px
  xxl: 90rem
);
