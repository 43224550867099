html {
  background: var(--tfa-color-body-back);
}

.tfa_site {
  /* FIGMA VARIABLES */
  --tfa-global-padding-inline: var(--font-size-xl2); // 24px
  --tfa-global-gutter: var(--font-size-xl2); // 24px
  --tfa-global-gutter-small: var(--font-size-base); // 16px
  --tfa-global-padding-block: var(--font-size-xl6); // 32px
  --tfa-global-button-height: var(--font-size-xl7); // 48px
  --tfa-global-inputfield-hight: var(--tfa-global-button-height);
  --tfa-global-button-padding: var(--font-size-lg); // 20px
  --tfa-global-button-min-width: 6.25rem; // 100px
  --tfa-global-spacer-block: 0.9375rem; // 15px
  --tfa-global-space-inline: var(--font-size-xl5); // 30px
  --tfa-type-styles-title-font-size: var(--font-size-xl7); // 48px
  --themes-minimal-body: var(--font-size-lg); // 20px
  --themes-minimal-caption: 1.0625rem; // 17px
  --themes-minimal-h1: 1.6875rem; // 27px
  --themes-minimal-h2: 1.6875rem; // 27px
  --themes-minimal-h3: var(--font-size-xl2); // 24px
  --themes-minimal-button-label: var(--font-size-base); // 16px
  --themes-minimal-note: var(--font-size-base); // 16px
  --themes-minimal-statement: var(--font-size-xl5); // 30px
  --themes-minimal-title: var(--themes-minimal-caption);
  --themes-minimal-nav: var(--themes-minimal-caption);
  --themes-minimal-tagline: var(--themes-minimal-body);
  --themes-minimal-event-calendar-month: 1.0625rem; // 17px
  --themes-minimal-event-calendar-day: 1.0625rem; // 17px
  --themes-classic-body: var(--font-size-lg); // 20px
  --themes-classic-caption: 1.0625rem; // 17px
  --themes-classic-h1: var(--font-size-xl6-5); // 40px
  --themes-classic-h2: 1.6875rem; // 27px
  --themes-classic-h3: var(--font-size-xl2); // 24px
  --themes-classic-button-label: var(--font-size-base); // 16px
  --themes-classic-note: var(--font-size-base); // 16px
  --themes-classic-statement: var(--font-size-xl5); // 30px
  --themes-classic-title: var(--font-size-lg); // 20px
  --themes-classic-nav: 1.0625rem; // 17px
  --themes-classic-tagline: var(--font-size-lg); // 20px
  --themes-classic-event-calendar-month: 1.0625rem; // 17px
  --themes-classic-event-calendar-day: 1.0625rem; // 17px
  --themes-poppins-body: var(--font-size-md); // 18px
  --themes-poppins-caption: var(--font-size-md); // 18px
  --themes-poppins-h1: var(--font-size-xl6-5); // 40px
  --themes-poppins-h2: 1.6875rem; // 27px
  --themes-poppins-h3: var(--font-size-xl2); // 24px
  --themes-poppins-button-label: var(--font-size-base); // 16px
  --themes-poppins-note: var(--font-size-base); // 16px
  --themes-poppins-statement: var(--font-size-xl5); // 30px
  --themes-poppins-title: var(--font-size-xl6); // 32px
  --themes-poppins-nav: var(--font-size-md); // 18px
  --themes-poppins-tagline: var(--font-size-md); // 18px
  --themes-poppins-event-calendar-month: var(--font-size-md); // 18px
  --themes-poppins-event-calendar-day: var(--font-size-md); // 18px
  --themes-belleza-body: var(--font-size-lg); // 20px
  --themes-belleza-caption: var(--font-size-lg); // 20px
  --themes-belleza-body-2: var(--font-size-lg); // 20px
  --themes-belleza-h1: var(--font-size-xl6-5); // 40px
  --themes-belleza-h2: 1.6875rem; // 27px
  --themes-belleza-h3: var(--font-size-xl2); // 24px
  --themes-belleza-button-label: 1.0625rem; // 17px
  --themes-belleza-note: var(--font-size-base); // 16px
  --themes-belleza-statement: var(--font-size-xl5); // 30px
  --themes-belleza-title: var(--font-size-lg); // 20px
  --themes-belleza-nav: var(--font-size-lg); // 20px
  --themes-belleza-tagline: var(--font-size-lg); // 20px;
  --themes-belleza-event-calendar-month: var(--font-size-lg); // 20px
  --themes-belleza-event-calendar-day: var(--font-size-lg); // 20px

  @include media-breakpoint-up(lg) {
    --tfa-global-padding-inline: 3.75rem; // 60px
    --tfa-global-gutter: 3.75rem; // 60px
    --tfa-global-gutter-small: var(--font-size-xl6-5); // 40px
    --tfa-global-padding-block: 3.75rem; // 60px
    --tfa-global-padding-collapsing: 3.75rem; // 60px
    --tfa-global-button-height: var(--font-size-xl8); // 64px
    --tfa-global-inputfield-hight: var(--tfa-global-button-height);
    --tfa-global-button-padding: var(--font-size-xl5); // 30px
    --tfa-global-button-min-width: 10rem; // 160px
    --tfa-global-spacer-block: var(--font-size-xl5); // 30px
    --tfa-global-space-inline: 3.75rem; // 60px
    --tfa-type-styles-title-font-size: var(--themes-minimal-title);
    --themes-minimal-body: var(--font-size-xl3); // 26px
    --themes-minimal-caption: var(--font-size-xl2); // 24px
    --themes-minimal-h1: var(--font-size-xl7); // 48px
    --themes-minimal-h2: var(--font-size-xl7); // 48px
    --themes-minimal-h3: var(--font-size-xl6); // 32px
    --themes-minimal-button-label: var(--font-size-lg); // 20px
    --themes-minimal-note: var(--font-size-lg); // 20px
    --themes-minimal-statement: var(--themes-minimal-h2);
    --themes-minimal-title: var(--font-size-xl7); // 48px
    --themes-minimal-nav: var(--themes-minimal-caption);
    --themes-minimal-tagline: var(--themes-minimal-body);
    --themes-minimal-event-calendar-month: var(--font-size-lg); // 20px
    --themes-minimal-event-calendar-day: var(--font-size-xl6); // 32px
    --themes-classic-body: var(--font-size-xl3); // 26px
    --themes-classic-caption: var(--font-size-xl2); // 24px
    --themes-classic-h1: var(--font-size-xl8); // 64px
    --themes-classic-h2: 3.625rem; // 58px
    --themes-classic-h3: 2.375rem; // 38px
    --themes-classic-button-label: var(--font-size-md); // 18px
    --themes-classic-note: var(--font-size-lg); // 20px
    --themes-classic-statement: 3.625rem; // 58px
    --themes-classic-title: var(--font-size-xl8); // 64px
    --themes-classic-nav: var(--font-size-xl2); // 24px
    --themes-classic-tagline: var(--font-size-xl3); // 26px
    --themes-classic-event-calendar-month: var(--font-size-lg); // 20px
    --themes-classic-event-calendar-day: 2.375rem; // 38px
    --themes-poppins-body: var(--font-size-xl); // 22px
    --themes-poppins-caption: var(--font-size-xl); // 22px
    --themes-poppins-h1: var(--font-size-xl8); // 64px
    --themes-poppins-h2: var(--font-size-xl7); // 48px
    --themes-poppins-h3: 2.375rem; // 38px
    --themes-poppins-button-label: var(--font-size-md); // 18px
    --themes-poppins-note: var(--font-size-lg); // 20px
    --themes-poppins-statement: var(--font-size-xl7); // 48px
    --themes-poppins-title: 5.25rem; // 84px
    --themes-poppins-nav: var(--font-size-xl2); // 24px
    --themes-poppins-tagline: var(--font-size-xl3); // 26px
    --themes-poppins-event-calendar-month: var(--font-size-xl); // 22px
    --themes-poppins-event-calendar-day: var(--font-size-xl6); // 32px
    --themes-belleza-body: var(--font-size-xl3); // 26px
    --themes-belleza-caption: var(--font-size-xl3); // 26px
    --themes-belleza-body-2: var(--font-size-xl3); // 26px
    --themes-belleza-h1: 5.875rem; // 94px
    --themes-belleza-h2: var(--font-size-xl8); // 64px
    --themes-belleza-h3: 2.375rem; // 38px
    --themes-belleza-button-label: var(--font-size-xl); // 22px
    --themes-belleza-note: var(--font-size-lg); // 20px
    --themes-belleza-statement: 3.625rem; // 58px
    --themes-belleza-title: 4.75rem; // 76px
    --themes-belleza-nav: var(--font-size-xl3); // 26px
    --themes-belleza-tagline: var(--font-size-xl3); // 26px
    --themes-belleza-event-calendar-month: var(--font-size-xl2); // 24px
    --themes-belleza-event-calendar-day: 2.375rem; // 38px
  }
}

/* Theme: Minimal */
.theme-minimal {
  /* Base Settings */
  --tfa-base-hero-variant: 'Subtle PageWidth';
  --tfa-font-headlines: 'Helvetica';
  --tfa-font-body: 'Crimson Text';
  --tfa-font-labels: 'Helvetica';

  /* Colors */
  --tfa-color-body-back: var(--editable-body-back);
  --tfa-color-box: var(--editable-box);
  --tfa-color-subtle-back: var(--fixed-subtle-back);
  --tfa-color-button: var(--editable-button);
  --tfa-color-text: var(--editable-text);

  /* Constants */
  --tfa-constant-button-label: var(--fixed-button-label);
  --tfa-constant-button-onbox: var(--fixed-button-onbox);
  --tfa-constant-button-label-onbox: var(--fixed-button-label-onbox);
  --tfa-constant-text-onbox: var(--fixed-text-onbox);
  --tfa-constant-inputfield-back: var(--fixed-inputfield-back);

  --tfa-elements-inputfield-border-width: 1px;
  --tfa-elements-inputfield-border-color: rgba(0, 0, 0, 0.3);
  --tfa-elements-divider-lines-divider-line-width: 1px;
  --tfa-elements-divider-lines-divider-header-line-width: 2px;
  --tfa-elements-divider-lines-divider-color: var(--fixed-divider-color);
  --tfa-elements-divider-lines-divider-header-color: var(--fixed-divider-header-color);

  /* Item Title */
  --tfa-type-styles-title-font-family: var(--tfa-font-labels);
  --tfa-type-styles-title-font-size: var(--themes-minimal-title);
  --tfa-type-styles-title-font-weight: 700;
  --tfa-type-styles-title-text-transform: 'none';
  --tfa-type-styles-title-line-height: 1.2;

  /* Headings */
  --tfa-type-styles-h1-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-h1-font-size: var(--themes-minimal-h1);
  --tfa-type-styles-h1-font-weight: 700;
  --tfa-type-styles-h1-letter-spacing: -1px;
  --tfa-type-styles-h1-line-height: 1.1;

  --tfa-type-styles-h2-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-h2-font-size: var(--themes-minimal-h2);
  --tfa-type-styles-h2-font-weight: 700;
  --tfa-type-styles-h2-letter-spacing: -1px;
  --tfa-type-styles-h2-line-height: 1.1;

  --tfa-type-styles-h3-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-h3-font-size: var(--themes-minimal-h3);
  --tfa-type-styles-h3-font-weight: 700;
  --tfa-type-styles-h3-line-height: 1.1;

  /* Body & Navigation */
  --tfa-type-styles-body-font-family: var(--tfa-font-body);
  --tfa-type-styles-body-font-weight: normal;
  --tfa-type-styles-body-font-size: var(--themes-minimal-body);
  --tfa-type-styles-body-line-height: 1.3;

  --tfa-type-styles-nav-font-family: var(--tfa-font-labels);
  --tfa-type-styles-nav-font-size: var(--themes-minimal-nav);
  --tfa-type-styles-nav-font-weight: 700;
  --tfa-type-styles-nav-line-height: 1.2;

  /* Caption */
  --tfa-type-styles-caption-font-family: var(--tfa-font-labels);
  --tfa-type-styles-caption-font-weight: 700;
  --tfa-type-styles-caption-font-size: var(--themes-minimal-caption);
  --tfa-type-styles-caption-line-height: 1.2;

  /* Footer Links */
  --tfa-type-styles-footer-links-font-family: var(--tfa-font-labels);
  --tfa-type-styles-footer-links-font-weight: 700;
  --tfa-type-styles-footer-links-font-size: var(--themes-minimal-caption);
  --tfa-type-styles-footer-links-letter-spacing: 0;
  --tfa-type-styles-footer-links-line-height: 1.2;

  /* Statement */
  --tfa-type-styles-statement-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-statement-font-weight: 700;
  --tfa-type-styles-statement-font-size: var(--themes-minimal-statement);
  --tfa-type-styles-statement-letter-spacing: -1px;
  --tfa-type-styles-statement-line-height: 1.2;

  /* Button */
  --tfa-type-styles-button-font-family: var(--tfa-font-labels);
  --tfa-type-styles-button-font-size: var(--themes-minimal-button-label);
  --tfa-type-styles-button-font-weight: 700;
  --tfa-type-styles-button-line-height: 1.2;
  --tfa-type-styles-button-border-radius: 0;
  --tfa-type-styles-button-border-width: 2px;

  /* Input Field */
  --tfa-type-styles-inputfield-border-color: #000000;
  --tfa-type-styles-inputfield-border-width: 1px;

  /* Note & Tagline */
  --tfa-type-styles-note-font-family: var(--tfa-font-body);
  --tfa-type-styles-note-font-weight: normal;
  --tfa-type-styles-note-font-size: var(--themes-minimal-note);
  --tfa-type-styles-note-line-height: 1.3;

  --tfa-type-styles-tagline-font-family: var(--tfa-font-body);
  --tfa-type-styles-tagline-font-weight: normal;
  --tfa-type-styles-tagline-font-size: var(--themes-minimal-tagline);
  --tfa-type-styles-tagline-line-height: 1.3;

  /* Base Layout */
  --tfa-base-header-alignment: left;
  --tfa-base-cover-style: 'card';
  --tfa-base-divider-line-width: 1px;
  --tfa-base-divider-line-color: var(--fixed-divider-color);

  /* Box Border */
  --tfa-constant-box-border-color: var(--fixed-box-border-color);
  --tfa-constant-box-border-width: var(--fixed-box-border-width);

  /* Navigation & Footer */
  --tfa-base-nav-activeaoption-underline-width: 2px;
  --tfa-constant-footer-border-width: var(--fixed-footer-border-width);
  --tfa-constant-footer-border-color: var(--fixed-footer-border-color);

  /* Event Calendar */
  --tfa-type-styles-event-calendar-month-font-family: var(--tfa-font-labels);
  --tfa-type-styles-event-calendar-month-font-weight: 700;
  --tfa-type-styles-event-calendar-month-font-size: var(
    --themes-minimal-event-calendar-month
  );
  --tfa-type-styles-event-calendar-month-line-height: 1.3;
  --tfa-type-styles-event-calendar-day-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-event-calendar-day-font-size: var(
    --themes-minimal-event-calendar-day
  );
  --tfa-type-styles-event-calendar-day-font-weight: 700;
  --tfa-type-styles-event-calendar-day-line-height: 1.1;
}

/* Mode: Classic */
.theme-classic {
  /* Base Settings */
  --tfa-base-hero-variant: 'String value';
  --tfa-font-headlines: 'EB Garamond';
  --tfa-font-body: 'EB Garamond';
  --tfa-font-labels: 'Helvetica';

  /* Colors */
  --tfa-color-body-back: var(--editable-body-back);
  --tfa-color-box: var(--editable-box);
  --tfa-color-subtle-back: var(--editable-subtle-back);
  --tfa-color-button: var(--editable-button);
  --tfa-color-text: var(--editable-text);

  /* Constants */
  --tfa-constant-button-label: var(--fixed-button-label);
  --tfa-constant-button-onbox: var(--fixed-button-onbox);
  --tfa-constant-button-label-onbox: var(--fixed-button-label-onbox);
  --tfa-constant-text-onbox: var(--fixed-text-onbox);
  --tfa-constant-inputfield-back: var(--fixed-inputfield-back);

  --tfa-elements-inputfield-border-width: 1px;
  --tfa-elements-inputfield-border-color: rgba(0, 0, 0, 0.2);
  --tfa-elements-inputfield-border-radius: 4px;
  --tfa-elements-divider-lines-divider-line-width: 1px;
  --tfa-elements-divider-lines-divider-header-line-width: 1px;
  --tfa-elements-divider-lines-divider-color: var(--fixed-divider-color);
  --tfa-elements-divider-lines-divider-header-color: var(--fixed-divider-header-color);

  /* Item Title */
  --tfa-type-styles-title-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-title-font-size: var(--themes-classic-title);
  --tfa-type-styles-title-font-weight: normal;
  --tfa-type-styles-title-letter-spacing: 5px;
  --tfa-type-styles-title-text-transform: 'uppercase';
  --tfa-type-styles-title-line-height: 1.2;

  /* Headings */
  --tfa-type-styles-h1-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-h1-font-size: var(--themes-classic-h1);
  --tfa-type-styles-h1-font-weight: normal;
  --tfa-type-styles-h1-line-height: 1.1;

  --tfa-type-styles-h2-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-h2-font-size: var(--themes-classic-h2);
  --tfa-type-styles-h2-font-weight: normal;
  --tfa-type-styles-h2-line-height: 1.1;

  --tfa-type-styles-h3-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-h3-font-size: var(--themes-classic-h3);
  --tfa-type-styles-h3-font-weight: normal;
  --tfa-type-styles-h3-line-height: 1.1;

  /* Body & Navigation */
  --tfa-type-styles-body-font-family: var(--tfa-font-body);
  --tfa-type-styles-body-font-weight: normal;
  --tfa-type-styles-body-font-size: var(--themes-classic-body);
  --tfa-type-styles-body-line-height: 1.3;

  --tfa-type-styles-nav-font-family: var(--tfa-font-labels);
  --tfa-type-styles-nav-font-size: var(--themes-classic-nav);
  --tfa-type-styles-nav-font-weight: 700;
  --tfa-type-styles-nav-line-height: 1.2;

  /* Caption */
  --tfa-type-styles-caption-font-family: var(--tfa-font-labels);
  --tfa-type-styles-caption-font-weight: 700;
  --tfa-type-styles-caption-font-size: var(--themes-classic-caption);
  --tfa-type-styles-caption-line-height: 1.2;

  /* Footer Links */
  --tfa-type-styles-footer-links-font-family: var(--tfa-font-labels);
  --tfa-type-styles-footer-links-font-weight: 700;
  --tfa-type-styles-footer-links-font-size: var(--themes-classic-caption);
  --tfa-type-styles-footer-links-letter-spacing: 0;
  --tfa-type-styles-footer-links-line-height: 1.2;

  /* Statement */
  --tfa-type-styles-statement-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-statement-font-weight: normal;
  --tfa-type-styles-statement-font-size: var(--themes-classic-statement);
  --tfa-type-styles-statement-line-height: 1.2;

  /* Button */
  --tfa-type-styles-button-font-family: var(--tfa-font-labels);
  --tfa-type-styles-button-font-size: var(--themes-classic-button-label);
  --tfa-type-styles-button-font-weight: 700;
  --tfa-type-styles-button-line-height: 1.2;
  --tfa-type-styles-button-border-radius: 32px;
  --tfa-type-styles-button-border-width: 2px;

  /* Input Field */
  --tfa-type-styles-inputfield-border-color: #000000;
  --tfa-type-styles-inputfield-border-width: 1px;

  /* Note & Tagline */
  --tfa-type-styles-note-font-family: var(--tfa-font-body);
  --tfa-type-styles-note-font-weight: normal;
  --tfa-type-styles-note-font-size: var(--themes-classic-note);
  --tfa-type-styles-note-line-height: 1.3;

  --tfa-type-styles-tagline-font-family: var(--tfa-font-body);
  --tfa-type-styles-tagline-font-weight: normal;
  --tfa-type-styles-tagline-font-size: var(--themes-classic-tagline);
  --tfa-type-styles-tagline-line-height: 1.3;

  /* Base Layout */
  --tfa-base-button-border-radius: 32px;
  --tfa-base-header-alignment: center;
  --tfa-base-cover-style: 'card';
  --tfa-base-divider-line-width: 1px;
  --tfa-base-divider-line-color: var(--fixed-divider-color);

  /* Box Border */
  --tfa-constant-box-border-color: var(--fixed-box-border-color);
  --tfa-constant-box-border-width: var(--fixed-box-border-width);

  /* Navigation & Footer */
  --tfa-base-nav-activeaoption-underline-width: 2px;
  --tfa-constant-footer-border-width: var(--fixed-footer-border-width);
  --tfa-constant-footer-border-color: var(--fixed-footer-border-color);

  /* Event Calendar */
  --tfa-type-styles-event-calendar-month-font-family: var(--tfa-font-labels);
  --tfa-type-styles-event-calendar-month-font-weight: 700;
  --tfa-type-styles-event-calendar-month-font-size: var(
    --themes-classic-event-calendar-month
  );
  --tfa-type-styles-event-calendar-month-line-height: 1.3;
  --tfa-type-styles-event-calendar-day-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-event-calendar-day-font-size: var(
    --themes-classic-event-calendar-day
  );
  --tfa-type-styles-event-calendar-day-font-weight: 700;
  --tfa-type-styles-event-calendar-day-line-height: 1.1;
}

/* Mode: Poppins */
.theme-poppins {
  /* Base Settings */
  --tfa-base-hero-variant: 'String value';
  --tfa-font-headlines: 'Poppins';
  --tfa-font-body: 'Poppins';
  --tfa-font-labels: 'Poppins';

  /* Colors */
  --tfa-color-body-back: var(--editable-body-back);
  --tfa-color-box: var(--editable-box);
  --tfa-color-subtle-back: var(--editable-subtle-back);
  --tfa-color-button: var(--editable-button);
  --tfa-color-text: var(--editable-text);

  /* Constants */
  --tfa-constant-button-label: var(--fixed-button-label);
  --tfa-constant-button-onbox: var(--fixed-button-onbox);
  --tfa-constant-button-label-onbox: var(--fixed-button-label-onbox);
  --tfa-constant-text-onbox: var(--fixed-text-onbox);
  --tfa-constant-inputfield-back: var(--fixed-inputfield-back);

  --tfa-elements-inputfield-border-width: 2px;
  --tfa-elements-inputfield-border-color: var(--editable-text);
  --tfa-elements-inputfield-border-radius: 4px;
  --tfa-elements-divider-lines-divider-line-width: 4px;
  --tfa-elements-divider-lines-divider-header-line-width: 4px;
  --tfa-elements-divider-lines-divider-color: var(--fixed-divider-color);
  --tfa-elements-divider-lines-divider-header-color: var(--tfa-color-text);

  /* Item Title */
  --tfa-type-styles-title-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-title-font-size: var(--themes-poppins-title);
  --tfa-type-styles-title-font-weight: 600;
  --tfa-type-styles-title-letter-spacing: -2px;
  --tfa-type-styles-title-text-transform: 'none';
  --tfa-type-styles-title-line-height: 1.2;

  /* Headings */
  --tfa-type-styles-h1-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-h1-font-size: var(--themes-poppins-h1);
  --tfa-type-styles-h1-font-weight: 600;
  --tfa-type-styles-h1-letter-spacing: -1px;
  --tfa-type-styles-h1-line-height: 1.1;

  --tfa-type-styles-h2-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-h2-font-size: var(--themes-poppins-h2);
  --tfa-type-styles-h2-font-weight: 600;
  --tfa-type-styles-h2-letter-spacing: -1px;
  --tfa-type-styles-h2-line-height: 1.1;

  --tfa-type-styles-h3-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-h3-font-size: var(--themes-poppins-h3);
  --tfa-type-styles-h3-font-weight: 600;
  --tfa-type-styles-h3-letter-spacing: -0.5px;
  --tfa-type-styles-h3-line-height: 1.1;

  /* Body & Navigation */
  --tfa-type-styles-body-font-family: var(--tfa-font-body);
  --tfa-type-styles-body-font-weight: normal;
  --tfa-type-styles-body-font-size: var(--themes-poppins-body);
  --tfa-type-styles-body-line-height: 1.3;

  --tfa-type-styles-nav-font-family: var(--tfa-font-labels);
  --tfa-type-styles-nav-font-size: var(--themes-poppins-nav);
  --tfa-type-styles-nav-font-weight: 600;
  --tfa-type-styles-nav-line-height: 1.2;

  /* Caption */
  --tfa-type-styles-caption-font-family: var(--tfa-font-labels);
  --tfa-type-styles-caption-font-weight: 600;
  --tfa-type-styles-caption-font-size: var(--themes-poppins-caption);
  --tfa-type-styles-caption-line-height: 1.2;

  /* Footer Links */
  --tfa-type-styles-footer-links-font-family: var(--tfa-font-labels);
  --tfa-type-styles-footer-links-font-weight: 600;
  --tfa-type-styles-footer-links-font-size: var(--themes-poppins-caption);
  --tfa-type-styles-footer-links-letter-spacing: 0;
  --tfa-type-styles-footer-links-line-height: 1.2;

  /* Statement */
  --tfa-type-styles-statement-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-statement-font-weight: 600;
  --tfa-type-styles-statement-font-size: var(--themes-poppins-statement);
  --tfa-type-styles-statement-letter-spacing: -0.5px;
  --tfa-type-styles-statement-line-height: 1.2;

  /* Button */
  --tfa-type-styles-button-font-family: var(--tfa-font-labels);
  --tfa-type-styles-button-font-size: var(--themes-poppins-button-label);
  --tfa-type-styles-button-font-weight: 600;
  --tfa-type-styles-button-line-height: 1.2;
  --tfa-type-styles-button-border-radius: 32px;
  --tfa-type-styles-button-border-width: 2px;

  /* Input Field */
  --tfa-type-styles-inputfield-border-width: 1px;
  --tfa-type-styles-inputfield-border-color: rgba(var(--bs-black-rgb), 0.5);
  --tfa-type-styles-inputfield-border-radius: 4px;

  /* Note & Tagline */
  --tfa-type-styles-note-font-family: var(--tfa-font-body);
  --tfa-type-styles-note-font-weight: normal;
  --tfa-type-styles-note-font-size: var(--themes-poppins-note);
  --tfa-type-styles-note-line-height: 1.3;

  --tfa-type-styles-tagline-font-family: var(--tfa-font-body);
  --tfa-type-styles-tagline-font-weight: normal;
  --tfa-type-styles-tagline-font-size: var(--themes-poppins-tagline);
  --tfa-type-styles-tagline-line-height: 1.3;

  /* Base Layout */
  --tfa-base-button-border-radius: 32px;
  --tfa-base-header-alignment: center;
  --tfa-base-cover-style: 'card';
  --tfa-base-divider-line-width: 4px;
  --tfa-base-divider-line-color: var(--fixed-divider-color);

  /* Box Border */
  --tfa-constant-box-border-color: var(--fixed-box-border-color);
  --tfa-constant-box-border-width: var(--fixed-box-border-width);

  /* Navigation & Footer */
  --tfa-base-nav-activeaoption-underline-width: 2px;
  --tfa-constant-footer-border-width: var(--fixed-footer-border-width);
  --tfa-constant-footer-border-color: var(--fixed-footer-border-color);

  /* Event Calendar */
  --tfa-type-styles-event-calendar-month-font-family: var(--tfa-font-labels);
  --tfa-type-styles-event-calendar-month-font-weight: 700;
  --tfa-type-styles-event-calendar-month-font-size: var(
    --themes-poppins-event-calendar-month
  );
  --tfa-type-styles-event-calendar-month-line-height: 1.3;
  --tfa-type-styles-event-calendar-day-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-event-calendar-day-font-size: var(
    --themes-poppins-event-calendar-day
  );
  --tfa-type-styles-event-calendar-day-font-weight: 700;
  --tfa-type-styles-event-calendar-day-line-height: 1.1;
}

/* Mode: Belleza */
.theme-belleza {
  /* Base Settings */
  --tfa-base-hero-variant: 'String value';
  --tfa-font-headlines: 'Belleza';
  --tfa-font-body: 'Source Sans Pro';
  --tfa-font-labels: 'Source Sans Pro';

  /* Colors */
  --tfa-color-body-back: var(--editable-body-back);
  --tfa-color-box: var(--editable-box);
  --tfa-color-subtle-back: var(--editable-subtle-back);
  --tfa-color-button: var(--editable-button);
  --tfa-color-text: var(--editable-text);

  /* Constants */
  --tfa-constant-button-label: var(--fixed-button-label);
  --tfa-constant-button-onbox: var(--fixed-button-onbox);
  --tfa-constant-button-label-onbox: var(--fixed-button-label-onbox);
  --tfa-constant-text-onbox: var(--fixed-text-onbox);
  --tfa-constant-inputfield-back: var(--fixed-inputfield-back);

  --tfa-elements-inputfield-border-width: 2px;
  --tfa-elements-inputfield-border-color: #000000;
  --tfa-elements-divider-lines-divider-line-width: 1px;
  --tfa-elements-divider-lines-divider-header-line-width: 2px;
  --tfa-elements-divider-lines-divider-color: var(--fixed-divider-color);
  --tfa-elements-divider-lines-divider-header-color: var(--fixed-divider-header-color);

  /* Item Title */
  --tfa-type-styles-title-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-title-font-size: var(--themes-belleza-title);
  --tfa-type-styles-title-font-weight: 700;
  --tfa-type-styles-title-text-transform: 'none';
  --tfa-type-styles-title-line-height: 1.2;

  /* Headings */
  --tfa-type-styles-h1-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-h1-font-size: var(--themes-belleza-h1);
  --tfa-type-styles-h1-font-weight: 700;
  --tfa-type-styles-h1-line-height: 1.1;

  --tfa-type-styles-h2-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-h2-font-size: var(--themes-belleza-h2);
  --tfa-type-styles-h2-font-weight: 700;
  --tfa-type-styles-h2-line-height: 1.1;

  --tfa-type-styles-h3-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-h3-font-size: var(--themes-belleza-h3);
  --tfa-type-styles-h3-font-weight: normal;
  --tfa-type-styles-h3-line-height: 1.1;

  /* Body & Navigation */
  --tfa-type-styles-body-font-family: var(--tfa-font-body);
  --tfa-type-styles-body-font-weight: normal;
  --tfa-type-styles-body-font-size: var(--themes-belleza-body);
  --tfa-type-styles-body-line-height: 1.3;

  --tfa-type-styles-nav-font-family: var(--tfa-font-body);
  --tfa-type-styles-nav-font-size: var(--themes-belleza-nav);
  --tfa-type-styles-nav-font-weight: 700;
  --tfa-type-styles-nav-line-height: 1.2;

  /* Caption */
  --tfa-type-styles-caption-font-family: var(--tfa-font-labels);
  --tfa-type-styles-caption-font-weight: 700;
  --tfa-type-styles-caption-font-size: var(--themes-belleza-caption);
  --tfa-type-styles-caption-line-height: 1.2;

  /* Footer Links */
  --tfa-type-styles-footer-links-font-family: var(--tfa-font-labels);
  --tfa-type-styles-footer-links-font-weight: 400;
  --tfa-type-styles-footer-links-font-size: var(--themes-belleza-caption);
  --tfa-type-styles-footer-links-letter-spacing: 0;
  --tfa-type-styles-footer-links-line-height: 1.2;

  /* Statement */
  --tfa-type-styles-statement-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-statement-font-weight: 700;
  --tfa-type-styles-statement-font-size: var(--themes-belleza-statement);
  --tfa-type-styles-statement-letter-spacing: -0.5px;
  --tfa-type-styles-statement-line-height: 1.1;

  /* Button */
  --tfa-type-styles-button-font-family: var(--tfa-font-labels);
  --tfa-type-styles-button-font-size: var(--themes-belleza-button-label);
  --tfa-type-styles-button-font-weight: 700;
  --tfa-type-styles-button-line-height: 1.2;
  --tfa-type-styles-button-border-radius: 32px;
  --tfa-type-styles-button-border-width: 2px;

  /* Input Field */
  --tfa-type-styles-inputfield-border-width: 1px;
  --tfa-type-styles-inputfield-border-color: rgba(var(--bs-black-rgb), 0.5);

  /* Note & Tagline */
  --tfa-type-styles-note-font-family: var(--tfa-font-body);
  --tfa-type-styles-note-font-weight: normal;
  --tfa-type-styles-note-font-size: var(--themes-belleza-note);
  --tfa-type-styles-note-line-height: 1.3;

  --tfa-type-styles-tagline-font-family: var(--tfa-font-body);
  --tfa-type-styles-tagline-font-weight: normal;
  --tfa-type-styles-tagline-font-size: var(--themes-belleza-tagline);
  --tfa-type-styles-tagline-line-height: 1.3;

  /* Base Layout */
  --tfa-base-button-border-radius: 32px;
  --tfa-base-header-alignment: left;
  --tfa-base-cover-style: 'card';
  --tfa-base-divider-line-width: 4px;
  --tfa-base-divider-line-color: var(--fixed-divider-color);

  /* Box Border */
  --tfa-constant-box-border-color: var(--fixed-box-border-color);
  --tfa-constant-box-border-width: var(--fixed-box-border-width);

  /* Navigation & Footer */
  --tfa-base-nav-activeaoption-underline-width: 2px;
  --tfa-constant-footer-border-width: var(--fixed-footer-border-width);
  --tfa-constant-footer-border-color: var(--fixed-footer-border-color);

  /* Event Calendar */
  --tfa-type-styles-event-calendar-month-font-family: var(--tfa-font-labels);
  --tfa-type-styles-event-calendar-month-font-weight: 700;
  --tfa-type-styles-event-calendar-month-font-size: var(
    --themes-belleza-event-calendar-month
  );
  --tfa-type-styles-event-calendar-month-line-height: 1.3;
  --tfa-type-styles-event-calendar-day-font-family: var(--tfa-font-headlines);
  --tfa-type-styles-event-calendar-day-font-size: var(
    --themes-belleza-event-calendar-day
  );
  --tfa-type-styles-event-calendar-day-font-weight: 700;
  --tfa-type-styles-event-calendar-day-line-height: 1.1;
}

.theme-minimal.variant-1 {
  --editable-body-back: #ffffff;
  --editable-box: #000000;
  --editable-button: #000000;
  --editable-text: #000000;

  --fixed-subtle-back: #f6f6f6;
  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: rgba(0, 0, 0, 0.02);
  --fixed-button-label: #ffffff;
  --fixed-button-onbox: #ffffff;
  --fixed-button-label-onbox: #000000;
  --fixed-box-border-color: #00ff00;
  --fixed-footer-border-width: 0;
  --fixed-footer-border-color: #00ff00;
  --fixed-divider-color: rgba(0, 0, 0, 0.25);
  --fixed-divider-header-color: var(--editable-text);

  --fixed-event-calendar-back: #f6f6f6;
  --fixed-event-calendar-border: #f6f6f6;
}

.theme-minimal.variant-2 {
  --editable-body-back: #fffef5;
  --editable-box: #57342d;
  --editable-button: #57342d;
  --editable-text: #57342d;

  --fixed-subtle-back: var(--editable-box);
  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: rgba(0, 0, 0, 0.02);
  --fixed-button-label: #ffffff;
  --fixed-button-onbox: #fbfaec;
  --fixed-button-label-onbox: #57342d;
  --fixed-box-border-color: #00ff00;
  --fixed-footer-border-width: 2px;
  --fixed-footer-border-color: #ffffff;
  --fixed-divider-color: var(--editable-text);
  --fixed-divider-header-color: var(--editable-text);

  --fixed-event-calendar-back: rgba(87, 52, 45, 0.1);
  --fixed-event-calendar-border: var(--tfa-color-body-back);
}

.theme-minimal.variant-3 {
  --editable-body-back: #000000;
  --editable-box: #000000;
  --editable-button: #ffffff;
  --editable-text: #ffffff;

  --fixed-subtle-back: #000000;
  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: #f6f6f6;
  --fixed-button-label: #000000;
  --fixed-button-onbox: #ffffff;
  --fixed-button-label-onbox: #000000;
  --fixed-box-border-width: 2px;
  --fixed-box-border-color: #ffffff;
  --fixed-footer-border-width: 2px;
  --fixed-footer-border-color: #ffffff;
  --fixed-divider-color: rgba(var(--bs-white-rgb), 0.66);
  --fixed-divider-header-color: rgba(var(--bs-white-rgb), 0.66);

  --fixed-event-calendar-back: rgba(255, 255, 255, 0.15);
  --fixed-event-calendar-border: var(--editable-body-back);
}

.theme-minimal.variant-4 {
  --editable-body-back: #162a56;
  --editable-box: #162a56;
  --editable-button: #90fc95;
  --editable-text: #90fc95;

  --fixed-subtle-back: #162a56;
  --fixed-text-onbox: var(--tfa-color-text);
  --fixed-inputfield-back: #ffffff;
  --fixed-button-label: #000000;
  --fixed-button-onbox: var(--tfa-color-button);
  --fixed-button-label-onbox: #000000;
  --fixed-box-border-width: 2px;
  --fixed-box-border-color: var(--tfa-color-text);
  --fixed-footer-border-width: 2px;
  --fixed-footer-border-color: var(--tfa-color-text);
  --fixed-divider-color: var(--tfa-color-text);
  --fixed-divider-header-color: var(--tfa-color-text);

  --fixed-event-calendar-back: rgba(144, 252, 149, 0.12);
  --fixed-event-calendar-border: var(--editable-body-back);
}

.theme-classic.variant-1 {
  --editable-body-back: #fffbf6;
  --editable-box: #22443d;
  --editable-subtle-back: #fffbf6;
  --editable-button: #22443d;
  --editable-text: #2f193b;

  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: #f6f6f6;
  --fixed-button-label: #ffffff;
  --fixed-button-onbox: #ffffff;
  --fixed-button-label-onbox: #000000;
  --fixed-box-border-width: 0;
  --fixed-box-border-color: #00ff00;
  --fixed-footer-border-width: 0;
  --fixed-footer-border-color: #00ff00;
  --fixed-divider-color: rgba(0, 0, 0, 0.35);
  --fixed-divider-header-color: rgba(0, 0, 0, 0.35);

  --fixed-event-calendar-back: var(--classic-editable-body-back);
  --fixed-event-calendar-border: var(--classic-editable-text);
}

.theme-classic.variant-2 {
  --editable-body-back: #202527;
  --editable-box: #202527;
  --editable-subtle-back: #202527;
  --editable-button: #c0962b;
  --editable-text: #ffffff;

  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: #f6f6f6;
  --fixed-button-label: #ffffff;
  --fixed-button-onbox: #c9a447;
  --fixed-button-label-onbox: #ffffff;
  --fixed-box-border-width: 1px;
  --fixed-box-border-color: rgba(var(--bs-white-rgb), 0.7);
  --fixed-footer-border-width: 2px;
  --fixed-footer-border-color: rgba(var(--bs-white-rgb), 0.7);
  --fixed-divider-color: rgba(var(--bs-white-rgb), 0.6);
  --fixed-divider-header-color: rgba(var(--bs-white-rgb), 0.6);

  --fixed-event-calendar-back: var(--classic-editable-body-back);
  --fixed-event-calendar-border: var(--classic-editable-text);
}

.theme-poppins.variant-1 {
  --editable-body-back: #ffd16c;
  --editable-box: #060606;
  --editable-subtle-back: #ffd16c;
  --editable-button: #000000;
  --editable-text: #000000;

  --fixed-subtle-back: var(--tfa-color-body-back);
  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: rgba(var(--bs-white-rgb), 0.9);
  --fixed-button-label: #ffffff;
  --fixed-button-onbox: #ffffff;
  --fixed-button-label-onbox: #000000;
  --fixed-box-border-color: #00ff00;
  --fixed-footer-border-color: #00ff00;
  --fixed-divider-color: var(--tfa-color-text);

  --fixed-event-calendar-back: var(--tfa-color-body-back);
  --fixed-event-calendar-border: var(--tfa-color-text);
}

.theme-poppins.variant-2 {
  --editable-body-back: #ffb9ab;
  --editable-box: #060606;
  --editable-subtle-back: #ffb9ab;
  --editable-button: #000000;
  --editable-text: #000000;

  --fixed-subtle-back: var(--tfa-color-body-back);
  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: rgba(var(--bs-white-rgb), 0.9);
  --fixed-button-label: #ffffff;
  --fixed-button-onbox: #ffffff;
  --fixed-button-label-onbox: #000000;
  --fixed-box-border-color: #000000;
  --fixed-footer-border-color: #000000;
  --fixed-divider-color: var(--tfa-color-text);

  --fixed-event-calendar-back: var(--tfa-color-body-back);
  --fixed-event-calendar-border: var(--tfa-color-text);
}

.theme-poppins.variant-3 {
  --editable-body-back: #fefaf3;
  --editable-box: #403b85;
  --editable-subtle-back: #403b85;
  --editable-button: #403b85;
  --editable-text: #403b85;

  --fixed-subtle-back: var(--tfa-color-box);
  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: rgba(var(--bs-white-rgb), 0.9);
  --fixed-button-label: #ffffff;
  --fixed-button-onbox: var(--editable-body-back);
  --fixed-button-label-onbox: var(--editable-text);
  --fixed-box-border-color: #00ff00;
  --fixed-footer-border-color: #00ff00;
  --fixed-divider-color: var(--tfa-color-text);
  --fixed-divider-header-color: var(--tfa-color-text);

  --fixed-event-calendar-back: var(--editable-body-back);
  --fixed-event-calendar-border: var(--editable-text);
}

.theme-poppins.variant-4 {
  --editable-body-back: #fefaf3;
  --editable-box: #403b85;
  --editable-subtle-back: #403b85;
  --editable-button: #403b85;
  --editable-text: #403b85;

  --fixed-subtle-back: #403b85;
  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: rgba(var(--bs-white-rgb), 0.9);
  --fixed-button-label: #ffffff;
  --fixed-button-onbox: var(--editable-body-back);
  --fixed-button-label-onbox: var(--editable-text);
  --fixed-box-border-color: #00ff00;
  --fixed-footer-border-color: #00ff00;
  --fixed-divider-color: #ffffff;
  --fixed-divider-header-color: #ffffff;

  --fixed-event-calendar-back: var(--tfa-color-body-back);
  --fixed-event-calendar-border: var(--tfa-color-text);
}

.theme-belleza.variant-1 {
  --editable-body-back: #faf5fc;
  --editable-box: #282449;
  --editable-subtle-back: #faf5fc;
  --editable-button: #282449;
  --editable-text: #282449;

  --fixed-subtle-back: var(--tfa-color-body-back);
  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: #ffffff;
  --fixed-button-label: #ffffff;
  --fixed-button-onbox: var(--tfa-color-body-back);
  --fixed-button-label-onbox: var(--tfa-color-text);
  --fixed-box-border-color: #00ff00;
  --fixed-footer-border-width: 0;
  --fixed-footer-border-color: #00ff00;
  --fixed-divider-color: rgba(1, 1, 1, 0.2);
  --fixed-divider-header-color: rgba(1, 1, 1, 0.2);

  --fixed-event-calendar-back: var(--editable-body-back);
  --fixed-event-calendar-border: var(--editable-text);
}

.theme-belleza.variant-2 {
  --editable-body-back: #f5fcfb;
  --editable-box: #244944;
  --editable-subtle-back: #f5fcfb;
  --editable-button: #244944;
  --editable-text: #244944;

  --fixed-subtle-back: var(--tfa-color-body-back);
  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: #ffffff;
  --fixed-button-label: #ffffff;
  --fixed-button-onbox: var(--tfa-color-body-back);
  --fixed-button-label-onbox: var(--tfa-color-text);
  --fixed-box-border-color: #00ff00;
  --fixed-footer-border-color: #00ff00;
  --fixed-divider-color: rgba(0, 0, 0, 0.2);
  --fixed-divider-header-color: rgba(0, 0, 0, 0.2);

  --fixed-event-calendar-back: var(--editable-body-back);
  --fixed-event-calendar-border: var(--editable-text);
}

.theme-belleza.variant-3 {
  --editable-body-back: #fcfaf5;
  --editable-box: #443e32;
  --editable-subtle-back: #fcfaf5;
  --editable-button: #443e32;
  --editable-text: #443e32;

  --fixed-subtle-back: var(--tfa-color-body-back);
  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: #ffffff;
  --fixed-button-label: #ffffff;
  --fixed-button-onbox: var(--tfa-color-body-back);
  --fixed-button-label-onbox: var(--tfa-color-text);
  --fixed-box-border-color: #00ff00;
  --fixed-footer-border-width: 0;
  --fixed-footer-border-color: #00ff00;
  --fixed-divider-color: rgba(0, 0, 0, 0.2);
  --fixed-divider-header-color: rgba(0, 0, 0, 0.2);

  --fixed-event-calendar-back: var(--editable-body-back);
  --fixed-event-calendar-border: var(--editable-text);
}

.theme-belleza.variant-4 {
  --editable-body-back: #ffffff;
  --editable-box: #301fbe;
  --editable-subtle-back: #ffffff;
  --editable-button: #301fbe;
  --editable-text: #202020;

  --fixed-subtle-back: var(--tfa-color-body-back);
  --fixed-text-onbox: #ffffff;
  --fixed-inputfield-back: var(--tfa-color-body-back);
  --fixed-button-label: #ffffff;
  --fixed-button-onbox: var(--tfa-color-body-back);
  --fixed-button-label-onbox: var(--tfa-color-text);
  --fixed-box-border-color: #00ff00;
  --fixed-footer-border-color: #00ff00;
  --fixed-divider-color: rgba(0, 0, 0, 0.2);
  --fixed-divider-header-color: rgba(0, 0, 0, 0.2);

  --fixed-event-calendar-back: var(--editable-body-back);
  --fixed-event-calendar-border: var(--editable-text);
}

.tfa_site {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--tfa-color-body-back);
  word-break: break-word;
  overflow-wrap: anywhere;

  overscroll-behavior: none;

  --bs-body-color: var(--tfa-color-text);
  --bs-body-font-family: var(--tfa-type-styles-body-font-family);
  --bs-body-font-size: var(--tfa-type-styles-body-font-size);
  --bs-body-font-weight: var(--tfa-type-styles-body-font-weight);
  --bs-body-line-height: var(--tfa-type-styles-body-line-height);
  --bs-border-radius: 0;
  --bs-border-radius-lg: 0;
  --bs-navbar-color: var(--tfa-color-text);

  main {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--tfa-global-padding-block);
    margin-inline: auto;
    width: 100%;
  }

  header,
  footer {
    margin-inline: auto;
    width: 100%;
  }

  & > .container {
    --bs-gutter-x: var(--padding-xl2);

    @include media-breakpoint-up(lg) {
      --bs-gutter-x: var(--padding-xl6);
    }
  }

  section {
    width: 100%;
    max-width: var(--site-max-width-desktop);
    padding-inline: var(--tfa-global-padding-inline);
  }

  footer {
    padding-block: var(--tfa-global-padding-block);
    padding-inline: var(--tfa-global-padding-inline);
  }

  header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    align-self: stretch;
    flex-wrap: wrap;
    flex-shrink: 0;
    padding-inline: 0;

    .navbar {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-block: var(--tfa-global-padding-block);
      margin-inline: var(--tfa-global-padding-inline);
      border-bottom: var(--tfa-elements-divider-lines-divider-header-line-width, 0.125rem)
        solid var(--tfa-elements-divider-lines-divider-header-color);

      @include media-breakpoint-up(lg) {
        gap: var(--padding-xl);
        padding-top: var(--padding-xl1);
        padding-bottom: var(--padding-xl);
      }
    }

    .headerPrimary {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;

      @include media-breakpoint-up(lg) {
        margin-inline: auto;
        width: 100%;
      }

      --bs-gutter-x: 0;

      .col-auto {
        flex-shrink: 1;
      }
    }

    .headerSecondary {
      margin-inline: auto;
      width: 100%;

      --bs-gutter-x: 0;

      @include media-breakpoint-down(lg) {
        .navbar-collapse {
          margin-top: var(--padding-xl);
          border-top: var(--tfa-base-divider-line-width, 0.25rem) solid
            var(--tfa-base-divider-line-color, #b9b9b9);
        }

        .navbar-nav {
          padding-block: var(--padding-xl);
        }

        .mainNav {
          padding-block-end: var(--padding-base);
        }

        .socialNav {
          padding-bottom: 0;

          li:first-of-type > .nav-link {
            margin-inline-start: 0;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        .navbar-collapse {
          justify-content: center;
          align-items: center;
          gap: var(--padding-xl3);
        }
      }

      .navbar-nav + .navbar-nav {
        padding-top: 0;
      }

      .col-auto {
        width: 100%;
      }
    }

    .siteSubtitle {
      margin-bottom: 0;
    }

    .mainNav {
      @include media-breakpoint-up(lg) {
        gap: var(--padding-base);
        margin-inline-start: auto;
      }
      @include media-breakpoint-up(xl) {
        gap: var(--padding-lg);
      }
    }

    .socialNav {
      display: flex;
      flex-wrap: wrap;
      gap: var(--padding-xs);
      transform: translateY(calc(var(--padding-xs2) * -1));

      .nav-link {
        padding-inline-end: var(--padding-xs1);
      }
    }
  }

  .nav-link {
    color: var(--tfa-color-text);
    white-space: nowrap;

    /* nav */
    font-family: var(--tfa-type-styles-nav-font-family);
    font-size: var(--tfa-type-styles-nav-font-size, 1.5rem);
    font-style: normal;
    font-weight: 700; // Original 700
    line-height: 130%; /* 1.95rem */
    letter-spacing: var(--tfa-type-styles-nav-letter-spacing, 0);

    &:hover,
    &.active {
      color: var(--tfa-color-text);
      text-decoration: underline;
      text-underline-offset: 0.5rem;
    }
  }

  @include media-breakpoint-up(sm) {
    padding-inline: 0;
  }

  .navbar-toggler {
    border-radius: var(--builder-border-radius-sm);
    border: none;
    padding: 0;
    svg {
      width: 2rem;
      height: 2rem;
      color: var(--tfa-color-text);
    }
  }

  &.pageBlock {
    display: flex;
    flex-direction: column;

    .affiliateDisclosure {
      margin-bottom: var(--padding-lg);
      color: var(--bs-muted);
      font-size: var(--bs-font-size-sm);
      font-style: italic;
    }
  }

  .blockTitle,
  .blockTitle a {
    color: var(--tfa-color-text);
    text-decoration: none;
  }

  .heroBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-inline: none;
    padding-block: var(--padding-xl2);
    gap: var(--padding-base);
    max-width: none;
    color: var(--hero-text-color, --bs-white);
    background: var(--hero-background-color, --tfa-color-box);

    &.withImage::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }

    .heroContent {
      position: relative;
      flex: 1;
      gap: var(--padding-xl);
      margin-inline: auto;
      max-width: var(--site-max-width-desktop);
      z-index: 2;
      --bs-gutter-x: 0;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
      }

      .row {
        align-items: center;
        justify-content: center;
        gap: var(--padding-xl);
        @include media-breakpoint-up(lg) {
          gap: var(--padding-xl2);
        }
      }

      & > div {
        --bs-gutter-x: 0;
        z-index: 2;
      }
    }

    .heroBody {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--padding-base);
    }

    .headline {
      font-family: var(--builder-hero-font-family);
      font-weight: 400;
    }

    .heroText p {
      margin: 0;
      color: var(--hero-text-color, --tfa-constant-text-onbox);
      word-break: break-word;
      overflow-wrap: anywhere;

      /* statement */
      font-family: var(--tfa-type-styles-statement-font-family, Helvetica);
      font-size: var(--tfa-type-styles-statement-font-size, 3rem);
      font-style: normal;
      font-weight: 700; // Original 700
      line-height: 130%; /* 3.9rem */
      letter-spacing: var(--tfa-type-styles-statement-letter-spacing);
    }

    .heroText a {
      color: var(--hero-text-color, --tfa-constant-text-onbox);
    }

    &.textAlignLEFT {
      text-align: left;
    }
    &.textAlignCENTER {
      text-align: center;
      .heroBody {
        align-items: center;
      }
    }

    .btn-dark {
      color: var(--tfa-constant-button-label-onbox);
      border-color: var(--tfa-constant-button-onbox);
      background-color: var(--tfa-constant-button-onbox);
    }

    .btn-outline-dark {
      --bs-btn-color: var(--tfa-constant-text-onbox);
      --bs-btn-border-color: var(--tfa-constant-text-onbox);
      --bs-btn-border-width: var(--tfa-type-styles-button-border-width);
    }

    .heroBooks {
      display: flex;
      flex-direction: row;
      justify-content: center;
      z-index: 2;
      gap: var(--padding-xs);

      @include media-breakpoint-up(md) {
        align-items: center;
        perspective: 1600px;
      }
      img {
        max-width: 100%;
        height: auto;
        object-fit: cover;
        max-height: 160px;
        box-shadow: none;

        @include media-breakpoint-up(md) {
          max-height: 12.5rem; // 200px
        }
        @include media-breakpoint-up(lg) {
          max-height: 16rem; // 256px
        }
      }
    }

    .bookLinkImage {
      position: relative;
      margin: 0;
      transform-origin: center;

      span {
        background: none;
        overflow: visible;
      }

      .productImage {
        margin: 0;
      }
    }

    &.heroBooks1 .bookLinkImage .productImage {
      @include media-breakpoint-up(md) {
        height: var(--builder-hero-only1cover-height);
        max-height: var(--builder-hero-only1cover-height);
      }
    }

    &.heroWithBooks:not(.textAlignLEFT) {
      .heroBody {
        align-items: center;
        text-align: center;
      }
    }

    &.heroBooks1 {
      .heroBooks {
        justify-content: center;
        .bookLinkImage img {
          max-height: 12.5rem; // 200px
          @include media-breakpoint-up(md) {
            max-height: 22.5rem; // 360px
          }
        }
      }
    }

    &.heroBooks2 {
      .heroBooks {
        display: flex;
        align-items: center;
        gap: var(--padding-xl);
      }
    }

    &.heroBooks3 {
      padding-block: var(--padding-xl4);
      .heroBooks {
        position: relative;
        gap: var(--padding-xl);
        .bookLinkImage:nth-child(1) {
          position: absolute;
          top: var(--padding-lg);
          z-index: 100;
          @include media-breakpoint-up(md) {
            top: var(--padding-xl);
          }
        }
      }
    }

    & + .newsletterBlock {
      margin-top: var(--tfa-global-padding-inline);
    }
  }

  .bookReviews {
    blockquote {
      margin-bottom: var(--padding-lg);
      p {
        display: inline;
      }
    }
  }

  .booksBlock {
    .bookReviews {
      b {
        font-weight: normal;
      }
    }
  }

  &.page_book {
    .pageTitleBlock {
      padding-bottom: 0;
    }
  }

  &.page_events {
    main {
      gap: 0;
    }
    .blockTitle:first-of-type {
      border-top: var(--tfa-elements-divider-lines-divider-line-width, 0.0625rem) solid
        var(--tfa-elements-divider-lines-divider-color, rgba(0, 0, 0, 0.25));
    }
  }

  .pageTitleBlock + .booksBlock {
    padding-top: 0;
  }

  .profileDD {
    width: 36px;
    height: 36px;
  }

  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    height: var(--tfa-global-button-height, 4rem);
    min-width: var(--tfa-global-button-min-width, 10rem);
    padding: 0rem var(--tfa-global-button-padding, 1.875rem);

    text-align: center;

    /* button-label */
    font-family: var(--tfa-type-styles-button-font-family);
    font-size: var(--tfa-type-styles-button-font-size, 1.125rem);
    font-weight: var(--tfa-type-styles-button-font-weight, 700);
    line-height: var(--tfa-type-styles-button-line-height, 120%);
    letter-spacing: var(--tfa-type-styles-button-letter-spacing, 0);
    border-radius: var(--tfa-type-styles-button-border-radius, 0);
    border-width: var(--tfa-type-styles-button-border-width, 0);
    font-style: normal;
  }

  .btn-dark {
    color: var(--fixed-button-label);
    border-color: var(--tfa-color-button);
    background-color: var(--tfa-color-button);
  }

  .btn-outline-dark {
    --bs-btn-color: var(--tfa-color-text);
    --bs-btn-border-color: var(--tfa-color-text);
    --bs-btn-border-width: var(--tfa-type-styles-button-border-width);
    --bs-btn-border-radius: var(--tfa-base-button-border-radius);
  }

  .siteTitle {
    margin-bottom: 0;
    color: var(--tfa-color-text);
    text-decoration: none;

    font-family: var(--tfa-font-headlines);
    font-size: var(--tfa-type-styles-title-font-size);
    font-weight: var(--tfa-type-styles-title-font-weight);
    line-height: var(--tfa-type-styles-title-line-height);
    letter-spacing: var(--tfa-type-styles-title-letter-spacing);
  }

  h1 {
    margin: 0;
    padding: 0;
    color: var(--tfa-color-text);

    /* h1 */
    font-family: var(--tfa-type-styles-h2-font-family);
    font-size: var(--tfa-type-styles-h1-font-size, 4rem);
    font-weight: var(--tfa-type-styles-h1-font-weight);
    line-height: var(--tfa-type-styles-h1-line-height, 1.1);
    letter-spacing: var(--tfa-type-styles-h1-letter-spacing);
    font-style: normal;
  }

  h2 {
    color: var(--tfa-color-text);

    /* h2 */
    font-family: var(--tfa-type-styles-h2-font-family);
    font-size: var(--tfa-type-styles-h2-font-size, 3rem);
    font-weight: var(--tfa-type-styles-h2-font-weight);
    line-height: var(--tfa-type-styles-h2-line-height, 1.1);
    letter-spacing: var(--tfa-type-styles-h2-letter-spacing);
    font-style: normal;
  }

  h3 {
    margin-bottom: 1.5rem;
    color: var(--tfa-color-text);

    /* h3 */
    font-family: var(--tfa-type-styles-h3-font-family);
    font-size: var(--tfa-type-styles-h3-font-size, 2rem);
    font-weight: var(--tfa-type-styles-h3-font-weight);
    line-height: var(--tfa-type-styles-h3-line-height, 1.1);
    letter-spacing: var(--tfa-type-styles-h3-letter-spacing);
    font-style: normal;
  }

  h4 {
    font-size: 1rem;
    font-weight: 700;
  }

  p,
  blockquote {
    font-family: var(--tfa-type-styles-body-font-family);
    font-size: var(--tfa-type-styles-body-font-size, 1.625rem);
    font-weight: var(--tfa-type-styles-body-font-weight, normal);
    line-height: var(--tfa-type-styles-body-line-height, 1.3);
    letter-spacing: var(--tfa-type-styles-body-letter-spacing, 0);
    font-style: normal;
  }

  .pageTitleBlock {
    padding-top: var(--tfa-global-padding-block, 3.75rem);
    padding-right: var(--tfa-global-padding-inline, 3.75rem);
    padding-bottom: var(--tfa-global-spacer-block, 1.875rem);
    padding-left: var(--tfa-global-padding-inline, 3.75rem);
    max-width: var(--site-max-width-desktop);
  }

  .pageCaption {
    /* caption */
    font-family: var(--tfa-type-styles-caption-font-family);
    font-size: var(--tfa-type-styles-caption-font-size, 1.5rem);
    font-weight: var(--tfa-type-styles-caption-font-weight);
    line-height: var(--tfa-type-styles-caption-line-height, 1.2);
    letter-spacing: var(--tfa-type-styles-caption-letter-spacing, 0);
    font-style: normal;
  }

  .bookCaption {
    margin-bottom: 0;
    color: var(--tfa-color-text);

    /* caption */
    font-family: var(--tfa-type-styles-caption-font-family);
    font-size: var(--tfa-type-styles-caption-font-size, 1.5rem);
    font-weight: var(--tfa-type-styles-caption-font-weight);
    line-height: var(--tfa-type-styles-caption-line-height, 1.2);
    letter-spacing: var(--tfa-type-styles-caption-letter-spacing, 0);
    font-style: normal;

    & + .bookDescription {
      margin-top: var(--padding-lg);
    }
  }

  &.page_home,
  &.page_book {
    main {
      padding-block: var(--tfa-global-padding-block);
      & > section .bookRow:first-child {
        border-top: 0;
      }
    }
  }

  &.page_home {
    .eventsBlock {
      .eventRow:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  .aboutAuthorBlock {
    .row {
      --bs-gutter-y: var(--padding-base);
    }
    .btn-outline-dark {
      width: fit-content;
    }
  }

  &.page_book {
    .bookTitle {
      display: block;
      margin-bottom: var(--padding-md);

      @include media-breakpoint-up(lg) {
        margin-bottom: var(--padding-xl);
      }

      a {
        display: block;
        text-decoration: none;
      }
    }

    .bookLinkImage {
      margin-bottom: var(--padding-md);
    }
  }

  .form-control {
    min-height: var(--bs-form-control-height);
    font-size: var(--bs-form-control-font-size);
    font-family: var(--tfa-type-styles-caption-font-family);
    border-width: var(--tfa-type-styles-inputfield-border-width);
    background: var(--tfa-constant-inputfield-back, #fff);
    border: var(--tfa-elements-inputfield-border-width, 1px) solid
      var(--tfa-elements-inputfield-border-color, rgba(0, 0, 0, 0.3));
    border-radius: var(--tfa-elements-inputfield-border-radius, 0rem);

    color: var(--bs-black, #000);
    --bs-form-control-border-color: var(--tfa-elements-inputfield-border-color, #000);
    --bs-form-control-border-width: var(--tfa-elements-inputfield-border-width, 0px);
    --bs-form-control-height: var(--tfa-global-inputfield-hight, 4rem);
    --bs-form-control-align-self: stretch;
    --bs-form-control-font-size: var(--tfa-type-styles-button-font-size, 1.125rem);
  }

  .form-label {
    color: var(--tfa-color-text);

    /* caption */
    font-family: var(--tfa-type-styles-caption-font-family);
    font-size: var(--tfa-type-styles-caption-font-size, 1.5rem);
    font-weight: var(--tfa-type-styles-caption-font-weight);
    line-height: var(--tfa-type-styles-caption-line-height, 1.2);
    letter-spacing: var(--tfa-type-styles-caption-letter-spacing, 0);
    font-style: normal;
  }

  footer {
    margin-top: var(--padding-xl3);
    margin-bottom: 0;
    padding-top: var(--padding-xl2);
    padding-bottom: var(--padding-xl3);
    color: var(--builder-footer-color);
    font-family: var(--bs-body-font-family);
    background: var(--tfa-color-box);
    border-top: var(--tfa-constant-footer-border-width) solid
      var(--tfa-constant-footer-border-color);
    border-bottom: 0;

    a,
    .nav-link {
      color: var(--tfa-constant-text-onbox, #fff) !important;
      text-decoration: none;
    }

    p {
      margin-inline: auto;
      margin-bottom: 0;
      max-width: var(--site-max-width-desktop);
      font-size: 1rem;
      color: var(--tfa-constant-text-onbox, #fff);

      /* note */
      font-family: var(--tfa-type-styles-note-font-family);
      font-size: var(--tfa-type-styles-note-font-size, 1.25rem);
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 1.625rem */
      letter-spacing: var(--tfa-type-styles-note-letter-spacing, 0);
    }

    .socialNav {
      margin-inline: auto;
      max-width: var(--site-max-width-desktop);
      margin-bottom: var(--padding-xl2);

      svg {
        margin-inline-end: var(--padding-base);
      }
      .nav-link {
        padding-inline: 0;
        font-family: var(--tfa-type-styles-footer-links-font-family);
        font-size: var(--tfa-type-styles-footer-links-font-size);
        font-weight: var(--tfa-type-styles-footer-links-font-weight);
        line-height: var(--tfa-type-styles-footer-links-line-height);
        letter-spacing: var(--tfa-type-styles-footer-links-letter-spacing);
      }
      &.isVertical {
        .nav-link {
          padding-block: var(--padding-sm);
        }
      }
    }
  }

  &[data-theme='loading'] {
    body {
      display: none;
    }
  }

  &.page_about {
    .aboutAuthorBlock {
      padding-top: 0;
    }
  }

  &.page_contact {
    .contactBlock {
      border-top: none;
    }
  }
}

.theme-minimal {
  .headerSecondary {
    @include media-breakpoint-up(md) {
      margin-inline-start: auto;
    }
  }

  .newsletterBlock {
    .blockTitle {
      @include media-breakpoint-up(md) {
        font-size: 2.625rem;
      }
      font-style: normal;
      line-height: 100%;
    }
  }
}

.theme-classic {
  .siteTitle {
    text-transform: uppercase;
  }
}

.theme-classic.variant-2 {
  .newsletterBlock {
    .btn {
      color: var(--tfa-constant-button-label);
      background-color: var(--tfa-color-button);
      border-color: var(--tfa-color-button);
    }
  }
}

.theme-classic,
.theme-poppins {
  header {
    .headerPrimary {
      & > div:first-of-type {
        width: 100%;
        padding-inline-end: var(--padding-base);
      }
    }
    @include media-breakpoint-up(lg) {
      .navbar {
        gap: var(--padding-xl);
      }
      .mainNav {
        gap: var(--padding-base);
        margin-inline-start: unset;
      }
      .navbar-collapse {
        justify-content: center;
      }
    }
  }

  .headerPrimary {
    text-align: center;
  }

  .heroBlock {
    background: var(--hero-background-color, --tfa-color-subtle-back);

    .heroBody {
      /* h2 */
      font-family: var(--tfa-type-styles-h2-font-family);
      font-size: var(--tfa-type-styles-h2-font-size, 3.625rem);
      font-weight: var(--tfa-type-styles-h2-font-weight);
      line-height: var(--tfa-type-styles-h2-line-height, 1.1);
      letter-spacing: var(--tfa-type-styles-h2-letter-spacing, 0);
      font-style: normal;
    }
    // .heroText p {
    //   color: var(--tfa-color-text, #2f193b);
    // }
    .btn-dark {
      color: var(--tfa-constant-button-label);
      border-color: var(--tfa-color-button);
      background-color: var(--tfa-color-button);
    }
    .btn-outline-dark {
      // --bs-btn-color: var(--tfa-constant-button-label-onbox);
      --bs-btn-border-color: var(--tfa-color-button);
    }
  }
}

.theme-belleza {
  .headerSecondary {
    margin-inline-start: auto;
  }
}

.backToEditorBar {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--padding-base);
  padding-block: var(--padding-md);
  padding-inline: var(--padding-lg);
  background-color: var(--editor-topbar-background);
  box-shadow: var(--editor-topbar-boxshadow);
  z-index: 100;

  @include media-breakpoint-up(md) {
    gap: 0;
  }

  .backToEditorRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
  }

  .backToEditorBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: var(--padding-xs2);
    flex-shrink: 0;
    padding-inline: var(--padding-base);
    min-width: var(--editor-topbar-backbutton);
    border: none;
    border-radius: var(--bs-border-radius-pill);
    --bs-btn-font-weight: 700;

    svg {
      margin-left: calc(var(--padding-sm) * -1);
    }
  }

  .backToEditorUrl {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    pointer-events: none;

    @include media-breakpoint-up(md) {
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      right: calc(var(--editor-topbar-backbutton) + var(--bs-gutter-x));
      bottom: 0;
      left: calc(var(--editor-topbar-backbutton) + var(--bs-gutter-x));
    }

    span {
      font-weight: 700;
    }
    a {
      font-size: var(--font-size-sm);
      text-underline-offset: var(--builder-underline-offset);
      pointer-events: all;
    }
  }

  .urlBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: var(--padding-xs2);
    padding-inline: var(--padding-lg);
    font-size: 0.75rem;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
    background: none;
    border: 0;
  }
}

header nav > .container-fluid {
  padding-inline: 0;
}

.pageCaption {
  display: block;
  padding-top: var(--padding-md);
}

.pageCaption + .pageTitle {
  padding-top: var(--padding-xs);
}

.btn-lg {
  --bs-btn-font-size: 1.125rem;
}

.coverStyleShadowHard,
.coverStyleShadowSoft {
  .bookLinkImage {
    img {
      box-shadow: var(--coverstyle-hard-boxshadow-sm);
      @include media-breakpoint-up(lg) {
        box-shadow: var(--coverstyle-hard-boxshadow-lg);
      }
    }
  }
}

// KEEPING THIS FOR FUTURE USE
// .coverStyleShadowSoft {
//   .bookLinkImage {
//     img {
//       box-shadow: var(--coverstyle-soft-boxshadow-sm);
//       border-radius: 0.875rem;
//       @include media-breakpoint-up(lg) {
//         box-shadow: var(--coverstyle-soft-boxshadow-lg);
//       }
//     }
//   }
// }

.bookLinkImage {
  display: flex;
  flex-direction: column;
  gap: var(--padding-base);
  text-decoration: none;

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: var(--tfa-color-subtle-back);
  }

  .productImage {
    margin-top: var(--padding-xl);
    margin-inline: var(--tfa-global-padding-inline, var(--padding-lg));
    margin-bottom: var(--padding-xl1);

    width: auto;
    height: var(--builder-cover-height-xs);
    max-width: var(--builder-cover-height-xs);
    max-height: var(--builder-cover-height-xs);

    @include media-breakpoint-up(md) {
      height: var(--builder-cover-height-sm);
      max-width: var(--builder-cover-height-sm);
      max-height: var(--builder-cover-height-sm);
    }
  }
}

.bookTitle {
  margin-bottom: 0;
  text-decoration: none;
  color: var(--tfa-color-text);

  /* h2 */
  font-family: var(--tfa-type-styles-h2-font-family, 'EB Garamond');
  font-size: var(--tfa-type-styles-h2-font-size, 3.625rem);
  font-weight: var(--tfa-type-styles-h3-font-weight);
  line-height: var(--tfa-type-styles-h3-line-height, 1.1);
  letter-spacing: var(--tfa-type-styles-h2-letter-spacing, 0rem);
}

.booksBlock {
  &.sectionLayoutDETAILED {
    .bookRow {
      justify-content: space-between;
      gap: var(--tfa-global-gutter);
      padding-block: var(--tfa-global-gutter);
      border-top: var(--tfa-elements-divider-lines-divider-line-width, 0.0625rem) solid
        var(--tfa-elements-divider-lines-divider-color, rgba(0, 0, 0, 0.25));
      border-bottom: var(--tfa-elements-divider-lines-divider-line-width, 0.0625rem) solid
        var(--tfa-elements-divider-lines-divider-color, rgba(0, 0, 0, 0.25));

      --bs-gutter-x: 0;

      @include media-breakpoint-up(md) {
        & > div {
          flex-shrink: 1;
          width: calc((100% - var(--tfa-global-gutter)) / 2);
        }
      }
    }

    .productImage {
      @include media-breakpoint-up(lg) {
        margin-block: var(--padding-xl2);
        height: var(--builder-cover-height-md);
        max-width: var(--builder-cover-height-md);
        max-height: var(--builder-cover-height-md);
      }
      @include media-breakpoint-up(xl) {
        height: var(--builder-cover-height-xl);
        max-width: var(--builder-cover-height-xl);
        max-height: var(--builder-cover-height-xl);
      }
    }

    .bookContent {
      display: flex;
      flex-direction: column;
      gap: var(--padding-base);
    }
  }

  &.sectionLayoutGRID {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--padding-xl);
    padding-block: 0;

    @include media-breakpoint-up(xxl) {
      grid-template-columns: repeat(3, 1fr);
    }

    .bookTitle {
      margin-bottom: var(--padding-md);

      /* caption */
      font-family: var(--tfa-type-styles-caption-font-family);
      font-size: var(--tfa-type-styles-caption-font-size, 1.5rem);
      font-weight: var(--tfa-type-styles-caption-font-weight);
      line-height: var(--tfa-type-styles-caption-line-height, 1.2);
      letter-spacing: var(--tfa-type-styles-caption-letter-spacing, 0);
    }

    @include media-breakpoint-down(sm) {
      .productImage {
        height: var(--builder-cover-height-xxs);
        max-width: var(--builder-cover-height-xxs);
        max-height: var(--builder-cover-height-xxs);
      }
    }
  }

  .bookActions {
    margin-bottom: var(--padding-sm);
  }

  & + .contactBlock {
    border-top: 0;
  }

  .bookRow + .bookRow {
    border-top: none;
  }
}

.bookBlock {
  .bookRow {
    --bs-gutter-x: 0;
    gap: var(--tfa-global-gutter);
  }
}

.eventsBlock {
  .blockTitle {
    padding-block: var(--tfa-global-padding-block, 2rem);

    @include media-breakpoint-up(lg) {
      padding-block: var(--tfa-global-padding-block, 3.75rem);
    }
    // padding-top: var(--padding-xl);
    // @include media-breakpoint-up(lg) {
    //   padding-top: var(--padding-xl2);
    // }
  }

  .eventsGroup {
    display: flex;
    flex-direction: column;
    gap: var(--padding-xl);

    @include media-breakpoint-up(lg) {
      gap: var(--padding-xl2b);
    }
  }

  .eventRow {
    gap: var(--padding-lg);
    align-items: flex-start;
    padding-bottom: var(--tfa-global-gutter);
    --bs-gutter-x: 0;

    &:not(:last-of-type) {
      border-bottom: var(--tfa-elements-divider-lines-divider-line-width, 0.0625rem) solid
        var(--tfa-elements-divider-lines-divider-color, rgba(0, 0, 0, 0.25));
    }

    @include media-breakpoint-up(lg) {
      gap: 0;
    }

    .eventMain {
      gap: var(--padding-lg);
      --bs-gutter-x: 0;

      @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
        gap: 0;

        .col-lg-auto:nth-child(2) {
          flex-shrink: 1;
        }
      }
    }
  }

  .eventDate {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-self: flex-start;
    gap: var(--padding-xs1);
    padding-block: var(--padding-xs);
    padding-inline: var(--padding-base);
    width: fit-content;
    color: inherit;
    text-decoration: none;

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.06;
      background: var(--tfa-color-text);
      border: 2px solid var(--fixed-event-calendar-border, #f6f6f6);
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      width: 5.625rem;
      aspect-ratio: 1;

      &:hover {
        outline: 1px solid var(--tfa-color-text);
      }
    }

    .eventMonth {
      /* event-calendar-month */
      font-family: var(--tfa-type-styles-event-calendar-month-font-family, Helvetica);
      font-size: var(--tfa-type-styles-event-calendar-month-font-size, 1.25rem);
      font-style: normal;
      font-weight: 700;
      line-height: 1;
      letter-spacing: var(--tfa-type-styles-button-letter-spacing, 0rem);
      text-transform: uppercase;
    }
    .eventDay {
      /* event-calendar-day */
      font-family: var(--tfa-type-styles-event-calendar-day-font-family, Helvetica);
      font-size: var(--tfa-type-styles-event-calendar-day-font-size, 2rem);
      font-style: normal;
      font-weight: 700;
      line-height: 1;
      letter-spacing: var(--tfa-type-styles-h3-letter-spacing, 0rem);
    }
  }

  .eventContent {
    display: flex;
    flex-direction: column;
    gap: var(--padding-lg);

    @include media-breakpoint-up(lg) {
      padding-inline: var(--padding-xl2);
    }
    @include media-breakpoint-up(xl) {
      padding-inline: var(--padding-xl2b);
    }
  }

  .eventTitle {
    margin-bottom: 0;
    text-decoration: none;
    color: var(--tfa-color-text);

    /* h3 */
    font-family: var(--tfa-type-styles-h3-font-family);
    font-size: var(--tfa-type-styles-h3-font-size, 2rem);
    font-weight: var(--tfa-type-styles-h3-font-weight);
    line-height: var(--tfa-type-styles-h3-line-height, 1.1);
    letter-spacing: var(--tfa-type-styles-h3-letter-spacing);
    font-style: normal;
  }

  .eventMetas {
    display: flex;
    flex-direction: column;
    gap: var(--padding-base);
  }

  .eventMeta {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--padding-xs);

    @include media-breakpoint-up(lg) {
      gap: var(--padding-sm);
    }

    /* caption */
    font-family: var(--tfa-type-styles-caption-font-family);
    font-size: var(--tfa-type-styles-caption-font-size, 1.5rem);
    font-weight: var(--tfa-type-styles-caption-font-weight);
    line-height: var(--tfa-type-styles-caption-line-height, 1.2);
    letter-spacing: var(--tfa-type-styles-caption-letter-spacing, 0);
    font-style: normal;

    .customIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.125rem;
      min-width: var(--tfa-type-styles-caption-font-size, 1.5rem);
      aspect-ratio: 1;
      img {
        height: 1.25rem;
        width: 1.25rem;
        @include media-breakpoint-up(lg) {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .eventDescription p {
    margin-bottom: 0;
  }

  .eventLinkImage {
    display: flex;
    flex-direction: column;
    gap: var(--padding-base);
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      &:hover {
        outline: 1px solid var(--tfa-color-text);
      }
    }

    .inner {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: var(--padding-base);

      &::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.06;
        background: var(--tfa-color-text);
        border: 2px solid var(--fixed-event-calendar-border, #f6f6f6);
      }

      @include media-breakpoint-up(lg) {
        aspect-ratio: 16 / 9;
      }
    }

    .eventImage {
      width: auto;
      max-height: var(--builder-event-height-xs);

      @include media-breakpoint-up(lg) {
        max-height: var(--builder-event-height-md);
      }
    }
  }

  .blockBackLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--padding-xs);
    margin-top: var(--padding-lg);
    margin-bottom: var(--padding-xl);
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;

    /* caption */
    font-family: var(--tfa-type-styles-button-font-family);
    font-size: var(--tfa-type-styles-button-font-size, 1.125rem);
    font-weight: var(--tfa-type-styles-button-font-weight, 700);
    line-height: var(--tfa-type-styles-button-line-height, 120%);
    letter-spacing: var(--tfa-type-styles-button-letter-spacing, 0);
    font-style: normal;
  }

  .eventLink {
    color: inherit;
    text-underline-offset: 0.5rem;
  }

  .blockLink {
    margin-inline: auto;
    width: fit-content;
  }

  &.eventDetail {
    main {
      gap: 0;
    }
    .eventRow {
      border-bottom: 0;
    }
    .eventTitle {
      font-family: var(--tfa-type-styles-h2-font-family);
      font-size: var(--tfa-type-styles-h2-font-size, 3rem);
      font-weight: var(--tfa-type-styles-h2-font-weight);
      line-height: var(--tfa-type-styles-h2-line-height, 1.1);
      letter-spacing: var(--tfa-type-styles-h2-letter-spacing);
      font-style: normal;
    }
    .eventContent {
      gap: var(--padding-xl1);
      padding-inline: 0;
    }
    .eventMetas {
      gap: var(--padding-lg);
      @include media-breakpoint-up(lg) {
        gap: var(--padding-xl);
      }
    }
    .eventSimpleImage {
      @include media-breakpoint-up(lg) {
        padding-inline-start: var(--padding-xl2);
      }
    }
  }
}

.reviewsBlock {
  h2 {
    margin-top: var(--padding-xl);
  }

  .sectionBooks {
    @include media-breakpoint-up(md) {
      max-width: 75%;
    }

    .bookItem {
      display: flex;
      margin-bottom: var(--padding-xl2);
      padding-bottom: var(--padding-xl);
      border-bottom: 1px solid var(--tfa-base-divider-line-color, #b9b9b9);
    }

    .bookTitle {
      margin-top: 0;
      margin-bottom: var(--padding-lg);
    }
    a {
      display: block;
      text-decoration: none;
    }
    .productImage {
      margin-bottom: var(--padding-lg);
    }
  }

  &.sectionLayoutDETAILED {
    .sectionBooks {
      .bookItem:first-of-type {
        padding-top: 0;
      }
    }
  }
}

.productImage {
  max-height: 100%;
}

.bookCaption + .bookTitle {
  margin-top: 0;
}

.bookActions {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: var(--padding-base);

  .dropdown-menu {
    --bs-dropdown-bg: var(--tfa-color-body-back);
    --bs-dropdown-border-color: var(--tfa-type-styles-button-border-width);
    border: 1px solid var(--tfa-color-button);
  }

  .dropdown-item {
    font-family: var(--tfa-type-styles-button-font-family);
    font-size: var(--tfa-type-styles-button-font-size, 1.125rem);
    font-weight: var(--tfa-type-styles-button-font-weight, 700);
    line-height: var(--tfa-type-styles-button-line-height, 120%);
    letter-spacing: var(--tfa-type-styles-button-letter-spacing, 0);

    --bs-dropdown-item-padding-y: var(--padding-sm);
    --bs-dropdown-link-hover-color: var(--fixed-button-label);
    --bs-dropdown-link-hover-bg: var(--tfa-color-button);

    &:active,
    &.active {
      color: var(--fixed-button-label);
      background-color: var(--tfa-color-button);
    }
  }
}

.buyNowList {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--padding-base);

  .title {
    /* caption */
    font-family: var(--tfa-type-styles-caption-font-family);
    font-size: var(--tfa-type-styles-caption-font-size, 1.5rem);
    font-weight: var(--tfa-type-styles-caption-font-weight);
    line-height: var(--tfa-type-styles-caption-line-height, 1.2);
    letter-spacing: var(--tfa-type-styles-caption-letter-spacing, 0);
    font-style: normal;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: var(--padding-xs);
    margin-bottom: var(--padding-lg);
    padding-left: 0;
    list-style: none;
  }
}

.newsletterBlock {
  width: auto;
  padding-top: var(--padding-xl4);
  padding-bottom: var(--padding-xl5);
  padding-inline: var(--padding-lg);
  background: var(--tfa-color-box);
  border: var(--tfa-constant-box-border-width) solid var(--tfa-constant-box-border-color);

  @include media-breakpoint-up(md) {
    padding-inline: var(--padding-xl2);
  }
  @include media-breakpoint-up(lg) {
    padding-inline: var(--padding-xl3);
  }

  .blockTitle {
    color: var(--tfa-constant-text-onbox, #fff);
  }

  .blockSubtitle {
    margin-bottom: 0;
    color: var(--tfa-constant-text-onbox, #fff);
  }

  .blockTitle + form {
    margin-top: var(--tfa-global-space-inline, 3.75rem);
  }

  .blockSubtitle + form {
    margin-top: var(--tfa-global-space-inline, 3.75rem);
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--padding-xs);
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    input,
    button {
      border-radius: 0;
    }
  }

  .form-control {
    border: var(--tfa-type-styles-inputfield-border-width, 0px) solid
      var(--tfa-type-styles-inputfield-border-color, #000);
    background: var(--bs-white);
  }

  .btn {
    color: var(--fixed-button-label-onbox);
    background-color: var(--tfa-constant-button-onbox);
    border-color: var(--tfa-constant-button-onbox);
  }
}

.contactBlock {
  .row {
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  form {
    & > div {
      margin-bottom: var(--padding-md);
    }
  }
}
