@import 'bootstrap-overrides';

@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap-icons/font/bootstrap-icons';

@import 'bootstrap/scss/functions.scss';
@import 'bootstrap/scss/variables.scss';
@import 'bootstrap/scss/mixins.scss';
@import 'bootstrap/scss/helpers.scss';

@import 'base';

html,
body {
  height: 100%;
  min-height: 100%;
  overscroll-behavior: none;
}

body {
  cursor: default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown {
  .dropdown-menu {
    padding: var(--padding-xs);
    border: none;
    border-radius: var(--builder-border-radius);
    box-shadow: var(--builder-boxshadow);
    a {
      border-radius: var(--builder-border-radius-sm);
    }
  }

  .customDropdownToggle {
    width: 100%;
    text-align: left;
    color: var(--bs-dark);
    background-color: var(--bs-white) !important;
    --bs-btn-border-width: var(--builder-input-border-width);
    --bs-btn-border-color: var(--builder-input-border-color);
    min-height: calc(var(--builder-button-height) + 1px);
    appearance: none;
    &:hover {
      border-color: var(--builder-input-border-color);
    }
    &::after {
      display: none;
    }
  }

  .customDropdownMenu {
    position: absolute;
    width: 100%;
    max-height: 30rem; // 480px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    font-size: 1.25rem;
    z-index: 1050;
    .dropdown-item {
      padding-block: 0;
      padding-inline: var(--padding-lg);
      min-height: var(--builder-input-height);
      line-height: 2.75rem;
      border-radius: 0;
      &.active,
      &:active {
        color: inherit;
        background: var(--builder-color-dropdown-link-active-bg);
      }
    }
  }

  .profileDD {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--builder-height-button);
    height: var(--builder-height-button);
    border: 1px solid var(--bs-gray-300);
    border-radius: var(--bs-border-radius-pill);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.modal {
  .modal-dialog {
    .modal-header {
      border-bottom: 0;
    }

    .modal-header + .modal-body {
      padding-top: 0;
    }

    .modal-title {
      font-size: var(--font-size-base);
    }

    .modal-content {
      padding: var(--padding-base);
    }

    .modal-footer {
      border-top: 0;
    }
  }
}

.modal-small-centered {
  .modal-dialog {
    .modal-content {
      min-height: 386px;
    }
    .modal-title {
      font-size: var(--font-size-xl2);
      font-weight: 600;
    }
  }
}

.app {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100vh;
  user-select: none;
  background-color: var(--bs-white);

  --bs-link-color: var(--bs-primary);
  --bs-link-color-rgb: var(--bs-primary-rgb);

  &:before,
  &:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 999px;
    background: var(--bs-white);
    z-index: 2;
  }
  &:before {
    top: -998px;
  }
  &:after {
    bottom: -999px;
  }

  .appNotificationBar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--padding-xs);
    background-color: var(--bs-primary);
    color: var(--bs-white);
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    z-index: 100;
  }

  .appOuter {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .appInner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    flex: 1;

    @include media-breakpoint-up(md) {
      overflow: scroll;
      flex-direction: row;
      height: 100vh;
    }

    --bs-border-radius: 6px;

    .hideInApp {
      display: none !important;
    }

    .btn-primary {
      --bs-btn-color: var(--bs-white);
      --bs-btn-hover-color: var(--bs-white);
    }

    @include media-breakpoint-down(xl) {
      .sidebar {
        &.booksPanel {
          width: 100%;
          max-width: none;
        }
      }
    }

    .backToEditorBar {
      .btn-light {
        --bs-btn-bg: #e5e5e5;
      }
    }
  }

  .btn-light {
    --bs-btn-bg: var(--builder-color-bg-button-secondary);
    --bs-btn-border-color: transparent;
    --bs-btn-hover-border-color: transparent;
  }

  --bs-border-color: var(--builder-input-border-color);

  form {
    --bs-border-width: var(--builder-input-border-width);
    --bs-border-color: var(--builder-input-border-color);
  }

  .form-control,
  .form-select {
    min-height: var(--builder-input-height);
  }

  @include media-breakpoint-up(lg) {
    .offcanvas-backdrop,
    .offcanvas.menu {
      display: none;
    }
  }

  .offcanvas {
    --bs-offcanvas-width: 17rem;

    .appMenu {
      display: block;
      border: none;
    }
  }
}

.appMenu {
  display: none;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-block-end: 1px solid var(--builder-border-color);
  @include media-breakpoint-up(md) {
    border-block-end: none;
    border-inline-end: 1px solid var(--builder-border-color);
  }
  @include media-breakpoint-up(lg) {
    display: block;
    width: var(--builder-sidemenu-width);
  }

  header,
  footer {
    padding: var(--padding-lg);
    max-width: var(--builder-sidemenu-width);
  }

  .profileDD {
    .dropdown-menu {
      padding-inline: 0;
    }
    .dropdown-header {
      white-space: normal !important;
      overflow: visible !important;
      word-break: break-word;
      max-width: 100%;
    }
  }

  .upgradeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--bs-border-radius-pill);
    border-width: 2px;
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-active-color: var(--bs-white);
  }

  .publishBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--bs-border-radius-pill);
    border-width: 2px;
    width: 100%;
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-active-color: var(--bs-white);
  }

  .visitSiteDropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-bottom: var(--padding-sm);

    > .btn:first-child {
      border-start-start-radius: var(--bs-border-radius-pill);
      border-end-start-radius: var(--bs-border-radius-pill);
    }

    .dropdown-toggle {
      border-inline-start: 1px solid var(--builder-border-color);
      border-end-end-radius: var(--bs-border-radius-pill);
      border-start-end-radius: var(--bs-border-radius-pill);
    }
  }

  .goToSiteBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-block: var(--padding-md);
    margin-inline: var(--padding-lg);
    margin-bottom: 0;
    border-radius: var(--bs-border-radius-pill);
    --bs-btn-font-weight: 600;
  }

  a {
    --bs-link-color: var(--bs-black);
    --bs-link-color-rgb: var(--bs-black-rgb);
    text-decoration: none;
  }

  nav {
    margin-inline: var(--padding-sm);

    ul {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      padding: 0;
      list-style: none;
      gap: var(--padding-xs3);
    }

    .menuIcon {
      max-height: 1.125rem; // 18px;
    }

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--padding-xs);
      padding-block: var(--builder-sidemenu-item-padding-block);
      padding-inline: var(--padding-base);
      font-size: 1rem;
      line-height: 1.2;
      border: none;
      font-weight: 600;
      border-radius: var(--bs-border-radius);
      overflow: hidden;

      &:hover {
        background-color: var(--bs-light);
      }

      &.active {
        padding-left: calc(var(--padding-base) - 4px);
        background-color: rgba(var(--bs-info-rgb), 0.1);
        border-left: 4px solid var(--bs-primary);
      }
    }

    .title {
      display: block;
      margin-top: var(--padding-lg);
      padding-block: var(--padding-xs);
      color: var(--bs-secondary);
      font-size: var(--font-size-xs);
      font-weight: 600;
      letter-spacing: 1px;
      pointer-events: none;
      text-transform: uppercase;
      opacity: 80%;
      cursor: pointer;
    }
  }

  .navPrimary {
    margin-bottom: var(--padding-xl);
  }

  .navSecondary {
    a {
      gap: var(--padding-xs3);
      color: var(--bs-gray-600);
      font-size: var(--font-size-xs);
      letter-spacing: 0.04rem;
      text-transform: uppercase;

      &.active {
        color: var(--bs-black);
      }
    }
  }
}

.offcanvas-header {
  padding: 0;
  z-index: 100;
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    width: 44px;
    height: 44px;
    background-color: var(--bs-white);
  }
}

.mobileNav {
  display: flex;
  flex-wrap: inherit;
  align-items: stretch;
  justify-content: space-between;
  top: 0;
  padding: var(--padding-xs1);
  min-height: var(-builder-toolbar-height);
  background-color: var(--bs-white);
  border-bottom: 1px solid var(--builder-border-color);
  z-index: 100;

  @include media-breakpoint-up(lg) {
    display: none;
    padding: 0;
    border: none;
  }

  @include media-breakpoint-up(lg) {
    .menuBtn {
      opacity: 0;
      pointer-events: none;
    }
  }

  .dropdown {
    align-self: center;
    margin-inline-start: var(--padding-xs);
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: var(--padding-xs2);
    font-weight: 600;

    svg {
      margin-left: -0.25rem; // -4px;
    }
  }

  .navbar-text {
    display: flex;
    align-items: center;
    margin-inline: auto;
    padding: 0;
  }

  .buttonlabel {
    display: inline-block;
  }

  .backBtn {
    @include media-breakpoint-up(lg) {
      margin-block-start: var(--builder-sidebar-spacing-inline);
      margin-inline-start: var(--builder-sidebar-spacing-inline-lg);
    }
    @include media-breakpoint-up(xl) {
      margin-inline-start: var(--builder-sidebar-spacing-inline-xl);
    }
  }

  .backBtn:disabled {
    opacity: 0;
    pointer-events: none;
  }

  &.withHistory {
    display: flex;
  }
}

.colorPickerPopover {
  border: none;
  box-shadow: var(--builder-boxshadow);
  --bs-popover-max-width: 15rem;
  @include media-breakpoint-up(md) {
    --bs-popover-max-width: 21rem;
  }
  .popover-body {
    padding: var(--padding-sm);
    @include media-breakpoint-up(md) {
      padding: var(--padding-md);
    }
  }

  .react-colorful {
    margin-bottom: var(--padding-sm);
    width: 100%;
    height: auto;
    & > div {
      margin-bottom: var(--padding-xs);
      border-radius: var(--builder-border-radius);
    }
    .react-colorful__pointer {
      width: 2rem;
      height: 2rem;
      border: 3px solid var(--bs-white);
      @include media-breakpoint-up(md) {
        width: 1.8rem;
        height: 1.8rem;
        border: 2px solid var(--bs-white);
      }
    }

    .react-colorful__interactive {
      box-shadow: inset -2px 2px 8px rgba(var(--bs-black-rgb), 0.15);
    }

    .react-colorful__interactive:focus .react-colorful__pointer {
      transform: translate(-50%, -50%) scale(1);
    }

    .react-colorful__saturation {
      height: 8rem;
      @include media-breakpoint-up(md) {
        height: 12rem;
      }
    }
    .react-colorful__hue,
    .react-colorful__alpha {
      height: 2rem;
      @include media-breakpoint-up(md) {
        height: 1.8rem;
      }
    }
  }
}

.colorPickerInput {
  display: flex;
  position: relative;

  .colorPickerBackdrop {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.isOpen {
    .colorPickerBackdrop {
      display: block;
      z-index: 100;
    }
  }

  .colorPickerBox {
    position: absolute;
    top: 0;
    left: 0;
  }

  .colorPicker {
    width: var(--builder-input-height);
    height: var(--builder-input-height);
    border: var(--builder-input-border-width) solid var(--builder-input-border-color);
    border-radius: var(--builder-border-radius);
    cursor: pointer;
    & + div {
      user-select: none;
    }
  }

  .form-control {
    padding-inline-start: 3.5rem;
    font-family: var(--bs-font-monospace);
  }
}

.colorFormatsList {
  user-select: none;
  .input-group {
    margin-bottom: var(--padding-xs);
  }
  .input-group-text {
    min-width: 4rem;
    justify-content: end;
    user-select: none;
  }
  .form-control {
    font-family: var(--bs-font-monospace);
  }
}

.colorPresets {
  .colorPresetGroup {
    margin-top: var(--padding-base);

    p {
      margin-bottom: var(--padding-xs2);
      padding-top: var(--padding-xs2);
      border-top: 1px solid var(--builder-border-color);
      user-select: none;
      font-size: var(--font-size-xs);
      text-transform: uppercase;
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      gap: var(--padding-xs2);
      div {
        width: calc(100% / 9);
        min-width: 2rem;
        height: 1.5rem;
        @include media-breakpoint-up(md) {
          height: 1.8rem;
        }
        border: 1px solid var(--builder-border-color);
        border-radius: var(--bs-border-radius-sm);
        cursor: pointer;
        aspect-ratio: 1;
      }
    }
  }
}

.sidebar {
  position: relative;
  width: 100%;
  overflow: auto;

  &.expanded {
    border: none;
  }

  @include media-breakpoint-up(md) {
    border-block-end: none;
    &:not(.expanded) {
      width: var(--builder-sidebar-width);
    }
  }

  @include media-breakpoint-up(xl) {
    &:not(.expanded) {
      width: var(--builder-sidebar-width-xl);
    }
    --builder-sidebar-spacing-inline: 2rem; // 32px;
  }

  .formSectionSmall {
    margin-bottom: var(--padding-base);
    color: var(--grays-gray-10-1a1a1a);
    font-size: var(--font-size-base);
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .panelTitleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: var(--padding-lg);
  }

  .panelTitle {
    margin-bottom: 0;
    font-size: 1.625rem; // 26px;
    font-weight: 600;
  }

  .panelSubtitle {
    color: var(--bs-secondary);
    font-weight: 500;
  }

  .sectionTitle {
    font-size: 1rem;
    margin-bottom: var(--padding-base);
  }

  &.homePanel,
  &.supportPanel {
    .panel {
      max-width: none;
    }
  }

  &.upgradePanel {
    .panel {
      max-width: 56.25rem;
    }
  }
}

.sidebarNavigation {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.builderForm {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: var(--builder-screen-max-width);

  .fieldSwitch {
    .form-check-input:checked {
      background-color: var(--bs-primary);
      border-color: var(--bs-primary);
    }
  }

  .fieldHeadline {
    margin-bottom: 0;
    .form-label {
      margin-bottom: 0;
      color: var(--bs-gray-600);
      font-size: var(--font-size-sm);
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }
  }

  .fieldDivider {
    margin-top: var(--padding-base);
    margin-bottom: var(--padding-sm);
    hr {
      width: 100%;
      opacity: 1;
      margin-block: var(--padding-xs2);
      border-top: 1px solid var(--bs-border-color);
    }
  }

  .fieldGroup {
    margin-top: var(--padding-md);
    .form-label {
      font-size: var(--font-size-lg);
    }
  }

  .fieldCheckbox {
    .form-check {
      padding-block: var(--padding-xs1);
    }
  }

  .themeField {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--padding-xl);

    .pagePreview {
      margin-bottom: 0;
      padding: 0;
      max-width: var(--builder-pagepreview-maxwidth);
    }
  }

  .fieldInactive {
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
  }

  .fieldComponent {
    .buyNowList {
      margin: 0;
      padding: var(--padding-base);
      border: 1px solid var(--builder-border-color);
      pointer-events: none;
      user-select: none;
      border-radius: var(--builder-border-radius);
      ul {
        margin: 0;
      }
    }
  }

  .fieldAddress {
    position: relative;

    .input-control {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
    }

    .input-group-text {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: var(--builder-input-height);
      z-index: 10;
      padding-inline-start: var(--padding-base);
      padding-inline-end: 0;
      background: none;
      border: 0;
    }

    .form-control {
      padding: 0;
    }

    input {
      position: relative;
      flex: 1 1 auto;
      min-width: 0;
      width: 100%;
      padding-inline-start: var(--padding-xl1);
      height: calc(var(--builder-input-height) - (var(--bs-border-width) * 2));
      border: 0;
      border-radius: var(--bs-border-radius);
    }

    .autocomplete-loading {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: var(--bs-border-width);
      right: var(--padding-xs);
      height: calc(var(--builder-input-height) - (var(--bs-border-width) * 2));
      padding: var(--padding-xs);
      font-size: var(--font-size-sm);
      background: var(--bs-white);
    }

    .autocomplete-suggestions {
      position: relative;
      margin: 0;
      padding: 0;
      width: 100%;
      list-style: none;
      overflow-y: auto;
      background: var(--bs-white);
      box-shadow: var(--builder-boxshadow);
      border-radius: var(--bs-border-radius);
      z-index: 1000;
    }

    .suggestion-item {
      display: flex;
      align-items: center;
      min-height: var(--builder-input-height);
      padding: var(--padding-xs) var(--padding-base);
      padding-left: calc(var(--padding-xl1) + var(--bs-border-width));
      cursor: pointer;

      &.active,
      &:hover,
      &:focus {
        background-color: rgba(var(--bs-info-rgb), 0.15);
      }

      &[aria-selected='true'] {
        background-color: rgba(var(--bs-info-rgb), 0.15);
      }
    }
  }

  .formField {
    position: relative;
    width: 100%;
    margin-bottom: var(--padding-lg);

    &.fieldHidden {
      display: none !important;
    }

    &.fieldHeadline,
    &.fieldDivider {
      margin-bottom: 0;
    }

    &.col-3 {
      width: calc(25% - 1rem);
      margin-right: 1rem;
    }

    &.col-4 {
      width: calc(33.3333% - 1rem);
      margin-right: 1rem;
    }

    .form-label {
      font-weight: 600;
      word-break: break-word;
      white-space: normal;
      overflow-wrap: break-word;
      line-break: auto;
    }

    &:not(.fieldRow) {
      .form-label {
        width: 100%;
      }
    }

    .form-text {
      display: block;
      color: var(--bs-secondary);
      user-select: all;
      word-wrap: break-word;
      overflow-wrap: anywhere;
    }

    .highlighted-text {
      font-weight: 600;
      font-size: var(--font-size-xs);
      line-height: var(--font-size-xs);
      margin-bottom: var(--padding-sm);
      color: var(--bs-body-color);
    }

    &:has(.description) {
      .form-label {
        margin-bottom: 0;
      }
      .description {
        margin-bottom: var(--padding-sm);
      }
    }
  }

  &.formLayoutRow > .formField:not(.fieldDivider):not(.fieldColorPalette),
  .formField.fieldRow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;

      & > div:first-of-type:not(.draggableList) {
        flex: 0 0 auto;
        width: 16.66666667%;
        padding-top: var(--padding-xs1);
        padding-right: var(--padding-base);
        line-break: anywhere;
      }

      .booksPanel & > div:first-of-type:not(.draggableList) {
        width: 17% !important;
      }
      .booksPanel & > div:nth-child(2):not(.draggableList) {
        max-width: 83% !important;
      }

      &.fieldText,
      &.fieldDropdown,
      &.fieldRadio,
      &.fieldFont,
      &.fieldColor,
      &.fieldImage {
        & > div:first-of-type {
          flex: 0 0 auto;
          width: 41.66666667%;
        }
      }
    }
  }

  .fieldDraggable {
    .form-label {
      margin-bottom: var(--padding-base);
    }

    .draggableList {
      .centerElements {
        padding-inline: var(--padding-sm);
      }
    }

    &.fieldNotEditable {
      pointer-events: none;
      user-select: none;
      .form-label,
      .listSection.outlinedItems {
        display: none;
      }
      .endElements {
        svg {
          display: none;
        }
      }
    }

    .edit-section_hero &.fieldNotEditable {
      pointer-events: all;
      user-select: auto;
      .form-label,
      .listSection.outlinedItems {
        display: block;
        pointer-events: none;
        .endElements {
          .btn {
            --bs-btn-color: var(--bs-secondary);
            --bs-btn-bg: var(--builder-input-back);
            --bs-btn-border-color: var(--builder-input-back);
          }
        }
      }
      .endElements {
        .btn > svg {
          display: block;
        }
        .dropdown-item svg {
          display: inline-block;
        }
      }
    }
  }

  .layout-option {
    display: flex;
    align-items: center;
    padding: var(--padding-sm);
    border: 2px solid transparent;
    border-radius: 8px;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: border-color 0.3s;
    background-color: var(--bs-light);
    .radio-title {
      font-weight: bold;
    }

    .fieldColorPalette {
      &.selected {
        outline: var(--builder-input-border-width) solid
          var(--builder-pagepreview-border-color-active);
      }
    }
  }

  .radio-content {
    display: flex;
    align-items: center;
  }

  .thumbnail {
    width: auto;
    height: 44px;
    background-color: #e9ecef;
    border-radius: 4px;
    margin-right: 1rem;
    img {
      max-height: 100%;
    }
  }

  .fieldRadioButtons {
    .layout-option {
      outline: var(--builder-input-border-width) solid
        var(--builder-pagepreview-border-color);

      &.selected {
        outline-color: var(--builder-pagepreview-border-color-active);
      }
    }
  }

  .fieldColorPalette {
    .paletteOptions {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: var(--padding-sm);

      @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .layout-option {
      display: flex;
      align-items: stretch;
      margin-bottom: 0;
      padding: 0;
      width: auto;
      min-height: 3.875rem;
      background: none;
      border: none;
      outline: var(--builder-input-border-width) solid
        var(--builder-pagepreview-border-color);
      overflow: hidden;

      &.selected {
        outline-color: var(--builder-pagepreview-border-color-active);
      }
    }

    .radio-labels {
      display: none;
    }

    .colorPalette {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      gap: var(--padding-xs2);
      padding: var(--padding-sm);
      width: 100%;
      min-height: 3.75rem;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 1;
        width: 1.375rem;
        height: 1.375rem;
        font-size: var(--font-size-lg);
        font-weight: bold;
        letter-spacing: -0.0065rem;
        outline: 1px solid rgba(var(--bs-white-rgb), 0.1);
        border-radius: 50%;
        aspect-ratio: 1;
      }

      & > div:first-child {
        margin-inline-end: var(--padding-xs2);
        outline: none;
      }
    }

    .customPalette {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-weight: 600;
      text-transform: capitalize;
    }

    & + .fieldHeadline .form-label {
      font-size: var(--font-size-sm);
    }
  }

  .radio-labels {
    display: flex;
    flex-direction: column;
  }

  .radio-subtitle {
    font-size: 0.875rem;
    color: #6c757d;
  }

  .regular-radio {
    margin-bottom: 0.5rem;
  }

  .book-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .book-details {
    flex-grow: 1;
  }

  .formImageField {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--padding-base);
    margin-bottom: 1rem;
  }

  .imagePreview {
    max-width: 6rem;
    max-height: 12rem;
  }

  .formActions {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin-top: var(--padding-lg);
    padding-block: var(--padding-md);
    gap: var(--padding-base);
    border-top: 1px solid var(--builder-border-color);

    .btn {
      min-height: var(--builder-formactions-button-height);
      font-weight: 600;
    }
  }

  .resetButton {
    position: absolute;
    padding-inline: 0;
    top: calc(50% - 1rem);
    right: -2rem;
    width: 2rem;
    opacity: 0.5;
    &:hover {
      background: none;
    }
  }

  .discardButton {
    min-width: 7rem; // 112px;
  }

  .saveButton {
    min-width: 12rem; // 192px;
  }

  .afterFormContent {
    width: 100%;
    padding-top: var(--padding-base);

    a {
      text-decoration: none;
      color: var(--bs-primary);
      font-weight: 600;
    }
  }
}

.fieldEditor {
  & > div {
    width: inherit;
  }
  .rdw-link-modal {
    width: auto !important;
    height: auto !important;
  }

  .wrapperRichTextEditor {
    border: var(--builder-input-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius-sm);
    a {
        cursor: pointer
    }
  }

  .toolbarRichTextEditor {
    margin: 0;
    padding-block: var(--padding-xs);
    padding-inline: var(--padding-sm);
    font-size: var(--font-size-base);
    background: none;
    border: 0;
    border-bottom: 1px solid var(--bs-border-color);
    border-radius: 0;

    & > div {
      margin-bottom: 0;
    }

    .rdw-option-wrapper {
      width: 2rem;
      height: 2rem;
      border: 0;
      border-radius: var(--bs-border-radius-sm);
      &:hover {
        box-shadow: none;
        outline: 1px solid var(--bs-border-color);
      }
    }

    .rdw-option-active {
      box-shadow: none;
      outline: 1px solid var(--bs-border-color);
    }
    .rdw-history-wrapper {
      margin-inline-start: auto;
    }
  }

  .editorRichTextEditor {
    padding-inline: var(--padding-base);
    min-height: 3rem;
    cursor: text;
    border-bottom-left-radius: var(--builder-border-radius-sm);
    border-bottom-right-radius: var(--builder-border-radius-sm);
  }

  .editorRichTextEditor {
    .public-DraftEditor-content {
      position: relative;
      min-height: 1.5em;
    }

    .public-DraftEditor-content:has(br)::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0.1rem;
      width: 0.125rem;
      height: 1.25rem;
      background-color: black;
      animation: blink 1s steps(1) infinite;
      pointer-events: none;
    }

    .public-DraftEditor-content:not(:focus-within)::after {
      display: none;
    }

    .public-DraftEditor-content:focus-within {
      outline: none;
    }

    &:has(.public-DraftEditor-content:focus-within) {
      border-color: var(--bs-primary);
      box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
      border-bottom-right-radius: var(--builder-border-radius-sm);
      border-bottom-left-radius: var(--builder-border-radius-sm);
    }
  }

  @keyframes blink {
    0%,
    50% {
      opacity: 1;
    }
    50.01%,
    100% {
      opacity: 0;
    }
  }
}

.privacy-policy_undefined {
  .toolbarRichTextEditor {
    justify-content: center;
  }
  .regular-switch {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding-left: 1rem;
    padding-right: 1rem;
    .form-check-input {
      width: 3em;
      height: 1.5em;
    }
  }
}

.panel {
  flex: 1 1 auto;
  width: 100%;
  max-width: var(--builder-panel-max-width);
  padding-block: var(--builder-sidebar-spacing-block);
  padding-inline: var(--builder-sidebar-spacing-inline);
  @include media-breakpoint-up(lg) {
    padding-block: var(--builder-sidebar-spacing-block-lg);
    padding-inline: var(--builder-sidebar-spacing-inline-lg);
  }
  @include media-breakpoint-up(xl) {
    padding-block: var(--builder-sidebar-spacing-block-xl);
    padding-inline: var(--builder-sidebar-spacing-inline-xl);
  }

  h2 {
    font-size: 1.625rem; // 26px;
  }

  .panelBody {
    padding-inline: 0;
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
}

.designPanel {
  .fieldDivider {
    height: 1px;
    hr {
      margin: 0;
    }
  }
  .fieldHeadline {
    .form-label {
      margin-block: var(--padding-base);
      color: var(--grays-gray-10-1a1a1a);
      font-size: var(--font-size-base);
      letter-spacing: 0.01rem;
    }
  }

  .fieldSpacer {
    hr {
      margin-bottom: 0;
    }
  }

  .draggableList {
    .draggableLinkItem {
      margin-bottom: 0;

      .centerElements {
        padding-block: var(--padding-base);
        border-radius: var(--builder-border-radius-lg);
      }

      .draggableTitle {
        font-weight: normal;
      }

      .draggableSubtitle {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: var(--padding-xs);
        align-items: flex-start;
        min-height: var(--font-size-lg);
        color: var(--bs-black);
        font-size: var(--font-size-base);
        font-weight: 600;
        overflow: visible;
        line-clamp: none;
      }
      .badge.bg-secondary {
        min-height: var(--font-size-lg);
        padding-block: var(--padding-xs2);
        color: var(--bs-gray-700);
        font-weight: 500;
        background: var(--builder-input-border-color) !important;
        border-radius: var(--builder-border-radius-sm);
      }
    }

    .pagePreview {
      .previewFrame {
        max-height: 10rem;
        outline: 0;
        border: 1.5px solid rgba(91, 106, 249, 0.2);
      }
    }
  }

  .draggableItemFooter {
    padding-block: var(--padding-xs);
    padding-inline: var(--padding-base);
    min-height: var(--font-size-sm);
    color: var(--bs-gray-900);
    font-size: var(--font-size-xs);
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background: var(--builder-border-color);
    border-radius: var(--builder-border-radius);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .centerElements {
    padding-inline: var(--padding-base);
    min-height: var(--builder-sidebar-draggable-item-height-lg);
    line-height: 1;
    background-color: transparent;
    border: var(--builder-input-border-width) solid var(--builder-border-color);

    &:hover {
      background-color: var(--builder-input-back);
    }

    &.withInnerTop {
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }

    .inner {
      gap: var(--padding-xs);
    }

    .end {
      .btn {
        pointer-events: none;
      }
      svg {
        display: none;
        @include media-breakpoint-up(md) {
          display: inline;
        }
      }
    }
  }

  .builderForm {
    margin-top: var(--padding-base);
  }

  .formField {
    margin-bottom: var(--padding-sm);
  }
}

.booksPanel {
  &.sidebar {
    @include media-breakpoint-up(lg) {
      border-inline-end: 1px solid var(--builder-border-color);
    }
  }
}

.draggableList {
  display: flex;
  flex-direction: column;
  gap: var(--padding-base);

  .addNewButton {
    display: flex;
    align-self: flex-end;
    svg {
      margin-left: calc(var(--padding-xs) * -1);
    }
  }

  .draggableSection {
    margin-bottom: var(--padding-sm);
    font-size: var(--font-size-base);
  }

  .draggableStatus {
    margin-top: var(--padding-sm);
    margin-bottom: var(--padding-sm);
    color: var(--grays-gray-06-8c8c8c);
    font-size: var(--font-size-sm);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.04375rem;
  }

  .btn-light {
    transition: none;
    --bs-btn-border-width: 0;
    --bs-btn-padding-y: var(--padding-xs1);
    --bs-btn-bg: var(--builder-input-back);
    --bs-btn-hover-bg: var(--builder-color-bg-button-secondary);
  }

  .btn-light:not(:has(.inner)) {
    font-size: var(--font-size-sm);
    font-weight: 600;
  }

  & + div.mt-5 {
    margin-top: 0 !important;
  }

  .draggableInner {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .innerTop {
    margin-bottom: var(--padding-md);
  }

  .innerBotton {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .draggableForm {
    display: flex;
    flex-direction: column;
    padding: var(--padding-base);
    border-end-start-radius: var(--bs-border-radius);
    border-end-end-radius: var(--bs-border-radius);

    .builderForm {
      margin-bottom: 0;
      padding-bottom: 0;
      &.formLayoutRow {
        .formField:not(.fieldDivider),
        .formField.fieldRow {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          @include media-breakpoint-up(xl) {
            flex-direction: row;
            &.fieldDropdown,
            &.fieldFont,
            &.fieldColor {
              & > div:first-of-type {
                flex: 0 0 auto;
                width: 16.66666667%;
              }
            }
          }
        }
      }
    }
  }

  .inEditing {
    box-shadow: var(--builder-boxshadow);

    .centerElements {
      flex-direction: column;
      align-items: start;

      .innerStart {
        display: none;
      }

      &.btn {
        &:hover,
        &:focus-visible,
        &:active {
          cursor: default;
          background-color: var(--builder-input-back);
          box-shadow: none;
          border-color: transparent;
        }
      }
    }
  }

  .draggableLinkItem {
    background: none;
    user-select: none;
    .draggableTitle {
      font-size: 1rem;
    }
    .endElements {
      padding-inline-end: var(--padding-base);
    }
  }

  .outlinedItems .draggableItem,
  .draggableInactive {
    .innerStart {
      display: none;
    }
    .endElements {
      background: var(--builder-input-back);
      border-top-right-radius: var(--builder-border-radius);
      border-bottom-right-radius: var(--builder-border-radius);
    }
  }

  .draggableAddNew {
    cursor: pointer;
  }

  .endElements {
    display: flex;
    align-items: center;
  }

  .draggableTitle,
  .draggableSubtitle {
    display: block;
    display: -webkit-box;
    align-items: center;
    padding-inline-end: 0.5rem;
    color: var(--bs-secondary);
    font-size: var(--font-size-sm);
    line-height: 1.2;
    text-align: left;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all;
  }

  .draggableTitle {
    color: var(--bs-black);
    font-weight: 600;
    p {
      margin: 0;
    }
  }

  .editable {
    cursor: pointer;
  }

  .draggableItemImage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-inline-end: var(--padding-xs);
    width: var(--builder-draggable-image-size);
    height: var(--builder-draggable-image-size);
    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: var(--builder-border-radius-xs);
    }
  }

  .nestedItems {
    margin-left: 1.5rem;
  }

  .nestedDraggable {
    background-color: #f0f0f0;
  }

  .editInputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: var(--padding-base);
    padding-top: var(--padding-base);
    border-top: 1px solid var(--builder-border-color);
  }

  .dropdown {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .dropdownToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    height: 100%;
    min-width: var(--builder-button-height);
    min-height: var(--builder-button-height);
    border-top-right-radius: var(--builder-border-radius);
    border-bottom-right-radius: var(--builder-border-radius);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-inline-start: 1px solid var(--builder-border-color);

    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-border-color: transparent;

    &:hover {
      border-color: var(--builder-border-color);
    }

    &::after {
      display: none;
    }
  }

  .useCompactExtraList {
    .innerList {
      padding-block: var(--padding-xs);
      max-height: var(--builder-sidebar-draggable-extras-height);
      overflow-y: auto;
      overflow-x: hidden;
      border: var(--builder-input-border-width) solid var(--builder-input-border-color);
      border-radius: var(--builder-border-radius);
    }

    .draggableItem {
      margin-bottom: 0;
      border: none;
      .centerElements {
        padding: var(--padding-xs2);
        padding-inline-start: calc(
          var(--padding-base) - var(--builder-input-border-width)
        );
        border-radius: 0;
        background: none;
      }
      .endElements {
        background: none;
      }
    }
  }
}

.centerElements {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-shrink: 0;
  padding-inline: var(--padding-base);

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    flex-shrink: 0;
  }

  &:hover {
    .innerStart {
      opacity: 1;
    }
  }
}

.draggableItem {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: var(--padding-base);
  min-height: var(--builder-draggable-item-height);
  border-radius: var(--bs-border-radius);
  user-select: none;

  &:has(.startElements),
  &:has(.endElements) {
    .centerElements {
      &.btn {
        border-start-end-radius: 0;
        border-end-end-radius: 0;
      }
    }
  }

  .innerStart {
    margin-inline-start: calc(var(--padding-xs3) * -1);
    margin-inline-end: var(--padding-xs2);
    line-height: 1;
    opacity: 0.5;
  }

  &.isSelected {
    .btn {
      background-color: rgba(var(--bs-info-rgb), 0.1);
      &.centerElements {
        padding-inline-start: calc(
          var(--padding-base) - var(--builder-draggable-active-border-width)
        );
        border-inline-start: var(--builder-draggable-active-border-width) solid
          var(--bs-primary);
      }
      &:hover {
        background-color: rgba(var(--bs-info-rgb), 0.3);
      }
    }
  }

  &.noHover {
    .draggableInner {
      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }

  &.noExpand {
    .draggableInner {
      cursor: default;
    }

    .endElements {
      display: none;
    }
  }
}

.confirmationModal {
  .draggableItem {
    pointer-events: none;
    .centerElements {
      background: none;
    }
    .endElements {
      display: none;
    }
  }
}

.codePreview {
  display: none;
  margin-top: 2rem;
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: var(--builder-border-radius);

  p {
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 600;
  }

  pre {
    margin: 0;
    overflow-x: auto;
    font-size: 0.6rem;
    line-height: 1.3;
  }
}

.screenPanel {
  flex: 1;

  @include media-breakpoint-up(md) {
    overflow: scroll;
  }

  &.pagesPanel {
    background: var(--builder-previewpanel-background);
  }

  background: var(--builder-btn-color);
  & > div {
    padding-block: var(--builder-sidebar-spacing-block);
    padding-inline: var(--builder-sidebar-spacing-inline);
    @include media-breakpoint-up(lg) {
      padding-block: var(--builder-sidebar-spacing-block-lg);
      padding-inline: var(--builder-sidebar-spacing-inline-lg);
    }
  }

  h1 {
    margin-bottom: var(--padding-xl);
    font-size: 1.625rem; // 26px;
  }

  h2,
  .panelTitle {
    margin-bottom: var(--padding-xl);
    font-size: var(--font-size-xl);
  }

  &.originalScale {
    padding-inline: var(--padding-lg);
    iframe {
      max-height: calc(100vh - 25px) !important;
      background-color: var(--builder-previewpanel-background);
      border: 1px solid var(--bs-black);
    }
  }

  @include media-breakpoint-up(lg) {
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .previewUrlBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--padding-base);
    padding-block: var(--padding-xs);
    padding-inline: var(--padding-lg);
    min-height: auto;
    color: var(--bs-black);
    font-size: var(--font-size-xs);
    line-height: 1;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
    background: none;
    border: 1px solid var(--builder-border-color);
    border-radius: var(--builder-border-radius);
  }

  .panelInner {
    width: 100%;
    padding-block: var(--builder-sidebar-spacing-block);
    padding-inline: var(--builder-sidebar-spacing-inline);
    @include media-breakpoint-up(lg) {
      padding-block: var(--builder-sidebar-spacing-block-lg);
      padding-inline: var(--builder-sidebar-spacing-inline-lg);
    }
    @include media-breakpoint-up(xl) {
      padding-block: var(--builder-sidebar-spacing-block-xl);
      padding-inline: var(--builder-sidebar-spacing-inline-xl);
    }
  }
  &:not(.booksPanel) {
    @include media-breakpoint-up(xl) {
      .builderForm {
        &.formLayoutRow {
          .formField {
            & > div:first-of-type:not(.quill) {
              flex: 0 0 auto;
              width: 16.66666667% !important;
              width: 10rem !important;
            }
          }
        }
      }
    }
  }

  .previewInner {
    width: 100%;
    padding-inline: var(--padding-base);
    padding-bottom: var(--padding-base);

    .pagePreview {
      margin-inline: auto;
      border: none;
      border-radius: 0;
      border: 1px solid var(--builder-color-gray7);
      max-width: var(--site-max-width-desktop);
    }

    .previewFrame {
      aspect-ratio: initial;
      border-radius: 0;
    }

    .card-body {
      width: 100%;
      .previewFrame {
        width: 100% !important;
        height: initial !important;
      }
    }

    iframe {
      width: 100%;
      transform-origin: top left;
      pointer-events: none;
      background-color: var(--bs-white);
    }

    &.previewMobile {
      max-width: 390px;

      &.scaledDown {
        iframe {
          width: 768px;
        }
      }
    }

    &.previewTablet {
      .pagePreview {
        max-width: var(--site-max-width-tablet);
      }
    }

    &.previewDesktop {
      .pagePreview {
        max-width: var(--site-max-width-desktop);
      }

      @include media-breakpoint-up(xl) {
        .btn-check + .btn {
          min-width: 100px;
        }

        .editButton {
          min-width: 120px;
        }
      }
    }
  }
}

.eventsPanel {
  .panelTitleRow {
    .d-none {
      display: flex !important;
    }
  }
  .draggableList {
    .outlinedItems:last-of-type {
      display: none;
    }
  }
}

.previewPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--builder-previewpanel-background);
  padding: 0 !important;
  width: 100%;
  min-height: 100%;

  .previewFrame {
    outline: 1px solid var(--builder-color-gray7);
  }
}

.homeScreen {
  background: var(--bs-white);
  --bs-nav-link-font-size: 1rem;

  h3 {
    font-size: 1.375rem;
    font-weight: 600;
  }

  .toDoBlock {
    ul {
      padding-left: 0;
      list-style: none;
    }
    li {
      margin-bottom: var(--padding-xs);
      padding: var(--padding-sm) var(--padding-base);
      background-color: #f9f9f9;
      border-radius: var(--builder-border-radius);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .completed {
      color: var(--alert-success-color);
      text-decoration: line-through;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    button {
      padding: 0;
      border: none;
      background: none;
    }
  }

  .card {
    padding: var(--padding-base);
    border-color: var(--builder-input-border-color);
    .small {
      font-size: 0.75rem;
    }
    ul {
      &:last-child {
        margin-bottom: 0;
      }
      li {
        margin-bottom: var(--padding-xs);
        span:last-child {
          font-weight: 600;
        }
      }
    }
  }

  .bigNumber {
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
  }

  .dashboardCards {
    h3 {
      margin-bottom: var(--padding-xs2);
    }
    ul {
      padding-left: 0;
      list-style: none;

      li {
        display: flex;
        justify-content: space-between;
        gap: var(--padding-lg);
      }
    }
  }
}

.siteList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--padding-lg);
}

.pagesGrid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  align-self: stretch;
  gap: var(--padding-xl);
  .pagePreview {
    width: 100%;
    max-width: var(--builder-pagepreview-pages-maxwidth);
  }
}

.pagePreview {
  display: flex;
  flex-direction: column;
  gap: var(--padding-sm);
  width: 100%;
  text-decoration: none;
  background: none !important;
  border: none;
  --bs-card-cap-padding-x: 0;
  --bs-card-border-width: 0;
  --bs-card-cap-bg: transparent;

  .card-body {
    flex: none;
    padding: 0;
    width: 100%;
  }

  .card-header {
    padding: 0;
    width: 100%;
    font-weight: 500;
    --bs-card-cap-bg: transparent;
    --bs-card-border-width: 0;
  }

  .previewFrame {
    position: relative;
    height: auto !important;
    outline: var(--builder-input-border-width) solid
      var(--builder-pagepreview-border-color);
    border-radius: var(--builder-border-radius);
    pointer-events: none;
    overflow: hidden;
    cursor: pointer;
    aspect-ratio: var(--builder-pagepreview-aspectratio);
  }

  &.fullWidth {
    .previewFrame {
      aspect-ratio: initial;
    }
  }

  iframe {
    pointer-events: none;
    background-color: var(--bs-white);
    border: none;
  }

  .card-footer {
    padding: 0;
    width: 100%;
    font-size: var(--font-size-sm);
    --bs-card-cap-color: var(--builder-color-recommendation);
    font-weight: 500;

    .slug {
      font-size: var(--font-size-xs);
      color: var(--bs-secondary);
    }
  }
}

.siteCard > .pagePreview,
.layout-option.pagePreview,
a.pagePreview {
  &:hover .previewFrame,
  &.active .previewFrame,
  &.selected .previewFrame {
    outline-color: var(--builder-pagepreview-border-color-active);
  }
}

.frame-root,
.frame-content {
  width: 100%;
}

.themeList {
  display: flex;
  flex-direction: column;
  margin-top: var(--padding-xl);
  gap: var(--padding-lg);
}

.themeTitle {
  display: block;
  margin-bottom: var(--padding-xs);
}

.themeCard {
  margin-bottom: var(--padding-xs2);
  border: none;
}

.goToSiteBar {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--padding-sm);
  width: 100%;
  min-height: var(--builder-toolbar-height);

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
  }

  .editButton {
    display: flex;
    min-height: var(--builder-button-height);
    justify-content: center;
    align-items: center;
    border: none;
    @include media-breakpoint-up(lg) {
      min-height: auto;
    }
  }

  .form-check {
    margin-bottom: 0;
  }

  .btn-check + .btn {
    border-width: 4px;
    border-color: var(--bs-btn-bg);
  }
  .btn-check:checked + .btn {
    background-color: var(--bs-white);
  }
}

.previewPanelTools {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--padding-xs);
  padding: var(--padding-base);
  width: 100%;

  .panelTitle {
    margin-bottom: 0;
    text-align: center;
    font-size: var(--font-size-base);
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .previewUrlBar {
    display: none;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }
}

.previewSwitcher {
  .btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--padding-xs2);
    font-size: var(--font-size-sm);

    svg {
      width: var(--font-size-xl3);
      height: var(--font-size-xl3);
    }

    @include media-breakpoint-up(xl) {
      flex-direction: row;
      gap: var(--padding-xs);
      font-size: var(--font-size-sm);
    }
  }
}

/* TO REVIEW */

.sidebar {
  .nav-link {
    padding-inline: var(--padding-xs2);
  }
}

.chooseThemeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: var(--padding-base);
  border-bottom: 1px solid var(--builder-border-color);
  min-height: var(--builder-onboarding-header-sm);

  @include media-breakpoint-up(lg) {
    padding-inline: var(--padding-lg);
    min-height: var(--builder-onboarding-header-lg);
  }

  .leftContainer {
    flex: 1;
    display: flex;
    justify-content: center;

    h1 {
      margin-bottom: 0;
      font-size: var(--font-size-base);
      font-weight: 600;
      letter-spacing: -0.01em;

      @include media-breakpoint-up(lg) {
        font-size: var(--font-size-lg);
      }
    }
  }
}

.dropdown-toggle {
  outline: 0;
}

.btn-toggle {
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  background-color: transparent;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
  background-color: var(--bs-tertiary-bg);
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
}

[data-bs-theme='dark'] .btn-toggle::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded='true'] {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
}
.btn-toggle[aria-expanded='true']::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: var(--bs-tertiary-bg);
}

.faq-container {
  font-family: var(--builder-font-family-inter);

  .faq-title {
    font-size: var(--font-size-lg); /* 20px / 16 = 1.25rem */
    line-height: var(--font-size-lg); /* 20px / 16 = 1.25rem */
    font-weight: 600;
    margin-bottom: var(--padding-lg) !important;
  }

  & > ul.border-none {
    border: 0px !important;

    & > li {
      border: 0px !important;
    }
  }

  & > ul {
    padding-left: 0;
    list-style: none;
    border-top: 1px solid var(--builder-border-color);

    & > li {
      font-family: var(--builder-font-family-inter);
      font-weight: 500;
      font-size: var(--font-size-base); /* 16px / 16 = 1rem */
      line-height: var(--font-size-xl); /* 22px / 16 = 1.375rem */
      list-style: none;
      border-bottom: 1px solid var(--builder-border-color);

      & > a {
        display: flex;
        width: 100%;
        align-items: baseline;
        padding-block: var(--padding-base);
        text-align: left;
        cursor: pointer;

        svg {
          margin-top: 0.188rem;
          min-width: var(--padding-base);
          min-height: var(--padding-base);
        }
      }
    }
  }

  .faq-answer {
    margin-top: calc(var(--padding-xs) * -1);
    margin-bottom: var(--padding-base);
    opacity: 0.7;
    font-weight: 400;
    font-size: var(--font-size-base);
    line-height: var(--font-size-xl); /* 22px / 16 = 1.375rem */
  }
}

.upgradePanel {
  font-family: var(--builder-font-family-inter);
  .dimension-card {
    cursor: pointer;
    min-width: 18.125rem; // 290px
    flex: 1;
    height: 14.375rem; //230px

    @include media-breakpoint-up(sm) {
      height: 16.875rem; //270px
    }

    &:hover,
    &:active,
    &:focus {
      border-color: var(--bs-primary);
    }
  }
  .section-title {
    font-weight: 600;
    font-size: var(--font-size-xl3); /* 26px / 16 = 1.625rem */
    line-height: var(--font-size-xl6); /* 32px / 16 = 2rem */
    margin-bottom: var(--padding-lg);
    margin-top: var(--padding-lg);
  }
  .list-benefit {
    margin-bottom: var(--padding-lg);
    list-style: none;
    padding-left: 0;
    & > li {
      margin-top: var(--padding-xs2);
      margin-bottom: var(--padding-xs2);
      & > i {
        margin-right: var(--padding-xs);
      }
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    border-width: 2px;

    .card-body {
      display: flex;
      flex-direction: column;
      padding: var(--padding-base) var(--padding-xs);
      @include media-breakpoint-up(sm) {
        padding: var(--padding-lg);
      }
      & > button {
        margin-top: auto;
      }
      .information-price {
        font-size: var(--font-size-xs);
        font-weight: 400;
        @include media-breakpoint-up(sm) {
          font-size: var(--font-size-sm);
        }
      }
      .subscription-card-price {
        font-size: var(--font-size-xl4);
        font-weight: bold;
        @include media-breakpoint-up(sm) {
          font-size: 2.75rem; /* 44px / 16 = 2.75rem */
        }
      }
    }
    .text-float {
      position: absolute;
      right: var(--padding-md);
      top: -1.188rem;
      color: var(--bs-white);
      background: var(--bs-primary);
      padding: var(--padding-xs2) var(--padding-base);
      border-radius: var(--bs-border-radius);
    }
  }
  * > .bi-check {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  .checkoutPartner {
    display: flex;
    align-items: center;
    gap: var(--padding-xs);
    padding-top: var(--padding-xl2);
    padding-bottom: var(--padding-xl1);
    color: var(--bs-secondary-color);
    & > img {
      height: 3.125rem;
    }
  }
}

.transform-30 {
  transform: rotate(30deg);
  transition: transform 0.2s ease;
}

.transform-120 {
  transform: rotate(120deg);
  transition: 'transform 0.2s ease';
}

.border-none {
  border: none;
}

.button-height-44 {
  height: 44px;
}

.no-underline {
  text-decoration: none;
}

.no-hover-btn:hover,
.no-hover-btn:focus,
.no-hover-btn:active {
  background-color: inherit !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex-a-center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-tooltip {
  .tooltip-inner {
    max-width: none !important;
    padding: var(--padding-base);
  }
}

.modal-claim {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 30rem;
  text-align: center;

  .input-group {
    display: flex;
    justify-content: center;
    .input-code {
      flex: none;
    }
  }

  .claim-title {
    margin-top: var(--padding-base);
    font-size: var(--font-size-xl2);
  }
  .claim-msg-error {
    color: var(--bs-danger);
    font-size: var(--font-size-base);
  }
  .claim-form {
    padding: 0;
    margin-bottom: var(--padding-base);
    .form-control {
      margin-bottom: var(--padding-base);
      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .button-link {
    padding: 0;
    font-size: var(--font-size-sm);
    color: var(--bs-secondary-color);
  }
  .confirmation-code-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.button-base {
  height: var(--padding-xl2);
  width: 100%;
  font-size: var(--font-size-md);
}

.input-code {
  font-size: var(--font-size-xl2);
  text-align: center;
  width: 55px !important;
  height: 60px;
  padding: 0;
  margin: 0;
}

.plan-billingPanel {
  h1 {
    font-weight: 600;
    font-size: var(--font-size-xl3); /* 26px / 16 = 1.625rem */
    line-height: var(--font-size-xl6); /* 32px / 16 = 2rem */
  }
  h2,
  h3 .subtitle {
    font-size: var(--font-size-lg);
  }
  .card {
    font-family: var(--builder-font-family-inter);
    padding: var(--padding-base) 0;
    margin-bottom: var(--padding-base);
    .badge {
      font-size: var(--font-size-sm);
      left: var(--padding-base);
      position: absolute;
      top: -0.7rem;
      padding: var(--padding-xs) var(--padding-base);
      border-radius: var(--bs-border-radius);
    }
    @include media-breakpoint-up(sm) {
      padding: var(--padding-base);
      .badge {
        left: var(--padding-xl);
      }
    }

    .badge {
      &.past-due {
        color: var(--builder-color-past-due);
        background-color: var(--builder-color-badge-past-due) !important;
      }
      &.canceled {
        color: var(--builder-color-badge-color-cancel);
        background-color: var(--builder-color-badge-bg-cancel) !important;
      }
    }
    .btn {
      &.past-due {
        background-color: var(--builder-color-past-due);
        border: none;
      }
    }
    .btn-link {
      display: block;
      padding: 0;
      font-size: var(--font-size-sm);
      &:hover {
        background: transparent;
      }
    }
  }
  .skeleton {
    height: 10rem;
    width: 100%;
    margin-bottom: var(--padding-lg);
  }
}

.mailing-listPanel {
  h1 {
    font-size: var(--font-size-xl3);
    margin-bottom: var(--padding-base);
  }
  .panel {
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(sm) {
      padding-left: var(--padding-lg);
      padding-right: var(--padding-lg);
    }
  }
  .form-check-input {
    transform: scale(1.7);
  }

  .button-download {
    height: var(--padding-xl2);
    font-size: var(--font-size-base);
  }

  .container-counter {
    padding: 0 var(--padding-sm);
    @include media-breakpoint-up(sm) {
      padding: 0 var(--padding-lg);
    }
    .firs-row {
      margin-bottom: var(--padding-base);
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
    & > div {
      border-left: 0;
      border-left: 1px solid var(--bs-card-border-color);
      text-align: left;
      p:first-child {
        color: var(--bs-secondary-color);
        margin-bottom: var(--padding-xs2);
      }
      p:last-child {
        font-weight: 600; // Original 700
        font-size: var(--padding-lg);
        margin-bottom: var(--padding-xs2);
      }
    }
  }
  .card {
    padding: var(--padding-base);
    @include media-breakpoint-up(sm) {
      padding: var(--padding-lg);
    }
    h2 {
      font-size: var(--font-size-base);
    }
  }

  .badge.bg-success {
    padding: var(--padding-xs1) var(--padding-sm);
    background-color: var(--builder-custom-light-green) !important;
    color: var(--bs-black);
  }
  .btn-link:hover {
    background: transparent;
  }
  .btn-link {
    padding: 0;
    text-align: left;
  }
  .banner-mailchimp {
    img {
      width: 3.125rem;
      height: 3.125rem;
      margin-right: var(--padding-base);
      min-width: 90px;
      align-self: center;
    }
  }
  .banner-mailerlite {
    img {
      width: 90px;
      height: auto;
      margin-right: var(--padding-base);
      object-fit: contain;
      flex-basis: 90px;
    }
  }
}
.dialog-content-bottom {
  .modal-dialog {
    display: flex;
    align-items: flex-end;
    margin: 0;
    @include media-breakpoint-up(sm) {
      margin-right: auto;
      margin-left: auto;
      align-items: flex-end;
      align-items: center;
      justify-content: center;
    }
  }
}

.dialog-content-center {
  .modal-dialog {
    margin: 0;
    display: flex;
    align-items: center;
    .modal-content {
      padding: var(--padding-base);
      .modal-body {
        padding-left: 0;
        padding-right: 0;
        @include media-breakpoint-up(sm) {
          padding: var(--bs-modal-padding);
        }
      }
    }
    @include media-breakpoint-up(sm) {
      margin-right: auto;
      margin-left: auto;
      align-items: flex-end;
      align-items: center;
      justify-content: center;
    }
  }
}

.domainPanel {
  .card {
    cursor: pointer;
    padding: var(--padding-lg);
  }

  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.register-newPanel,
.domainPanel,
.connect-existingPanel,
.confirm-domainPanel,
.import-DNS-settingsPanel,
.instruction-to-connectPanel,
.waiting-for-changePanel,
.plan-billingPanel,
.homePanel,
.edit-mailchimp-syncPanel,
.error-nameserversPanel,
.email-dns-configPanel {
  .panel {
    padding-inline: 0;
    @include media-breakpoint-up(sm) {
      padding-inline: var(--builder-sidebar-spacing-inline);
    }
    li {
      user-select: text;
    }
  }
}

.register-newPanel {
  .fs-12 {
    font-size: var(--font-size-xs);
  }

  .fs-14 {
    font-size: var(--font-size-sm);
  }
  .container-item-domain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 7.188rem;

    @include media-breakpoint-up(sm) {
      height: 5.625rem;
    }

    padding: var(--padding-xs) 0px;
    border-left: none;
    border-right: none;
    border-radius: 0;
    gap: var(--padding-base);
  }

  .item-domain {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 0;

    @include media-breakpoint-up(sm) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
    & > span {
      min-width: 0;
    }
    & > div {
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: 14.375rem;
      }
      & * {
        width: inherit;
      }
    }
  }

  .subtitle {
    font-size: var(--font-size-base);
    font-weight: 600;
    line-height: var(--font-size-base);
    letter-spacing: 0.01em;
  }

  .input-search {
    background: var(--builder-input-back);
    border-radius: var(--padding-xl3);
    border: none;
    height: 3.438rem;

    .input-group-text,
    .form-control {
      border: inherit;
      background: inherit;
      border-radius: inherit;

      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }

  .button-choose {
    height: var(--builder-height-button);
    padding: 0 var(--padding-lg);
    border-radius: var(--padding-xs);
    border: none;
    background: var(--builder-color-bg-button-secondary);
  }
  .button-clear {
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: var(--padding-xs);
    background: var(--builder-color-bg-button-secondary);
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
    margin-left: var(--padding-base) !important;
    margin-right: var(--padding-base);
  }
}

.connect-existingPanel {
  .input-group {
    gap: var(--padding-base);
    .form-control {
      border-radius: var(--bs-border-radius) !important;
    }
  }
  .arrow-button {
    width: 3.125rem;
    height: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}

.confirm-domainPanel {
  .card {
    background-color: var(--builder-input-back);
    border-radius: var(--padding-xs);
    padding: var(--padding-md);
    border: none;
    margin-bottom: var(--padding-lg);
  }
  .card-body {
    display: flex;
  }

  .text-domain {
    font-size: var(--font-size-xl);
    font-weight: 500;
    line-height: var(--font-size-xl);
    letter-spacing: -0.01em;
  }
}

.domainPanel,
.checkout-successPanel {
  .alert-success {
    background-color: var(--alert-success-bg-color);
    color: var(--alert-success-color);
    border: none;
  }
}

.section-title {
  font-weight: 600;
  font-size: var(--font-size-xl3);
  line-height: var(--font-size-xl6);
  margin-bottom: var(--padding-lg);
}

.container-button {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: var(--padding-sm);

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: flex-end;
  }
  button {
    width: fit-content;
    border-radius: var(--padding-xs1);
    height: var(--builder-height-button);
    border: none;
    padding: 0 var(--padding-lg);
  }
}

.button-dropdown {
  padding-left: 0;

  .btn-link {
    padding-left: 0;
    text-decoration: none;
    color: var(--bs-link-hover-color);
  }

  .btn-link:hover {
    background: transparent;
  }

  .button-dropdown-content {
    margin-left: var(--padding-lg);
  }
  button {
    text-align: start;
    display: flex;
    align-items: baseline;
  }
  svg {
    min-height: var(--padding-base);
    min-width: var(--padding-base);
  }
}

.with-divider {
  border-bottom: 1px var(--bs-border-style) var(--bs-border-color);
  margin-bottom: var(--padding-base);
  padding-bottom: var(--padding-xl2);
}

.container-instructions {
  ol {
    padding-left: var(--padding-base);
  }
  * {
    font-size: var(--font-size-base);
    font-weight: 400;
    line-height: var(--font-size-xl2);
    &.name-server {
      border: none;
      padding: 0;
      font-weight: 500;
    }
  }
}

.homeScreen {
  .sites-block {
    border: none;
    background: var(--builder-input-back);
    margin-bottom: var(--padding-lg);

    .siteCard {
      padding: 0;
    }

    .card-body {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: var(--padding-lg);
      .card-title {
        font-family: var(--font-itc-clear-face-regular);
        font-size: var(--font-size-xl2);
      }

      .btn-light {
        font-weight: 600;
        border-radius: var(--padding-xs);
        border: none;
        background: var(--builder-color-bg-button-secondary);
      }

      .btn-link {
        &:hover,
        &:active {
          background: none;
        }
      }
      @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
      }
    }

    .badge {
      padding: var(--padding-xs);
      color: #000;
    }
    .badge.primary {
      background-color: var(--builder-custom-light-blue) !important;
    }

    .badge.secondary {
      background-color: var(--builder-color-badge-bg-cancel) !important;
    }
  }
}

.supportScreen {
  h1 {
    font-size: var(--font-size-xl6);
    font-weight: 600;
    letter-spacing: 0em;
  }
  .contact-our-team {
    border-radius: --builder-border-radius;
    background-color: var(--grays-gray-00-f9f9f9);
    padding: var(--padding-xl);
    margin-top: var(--padding-xl);
    margin-bottom: var(--padding-xl);
    p {
      margin: 0;
    }
  }
  hr {
    border-top: 1px solid var(--grays-gray-03-d9d9d9);
  }
}

.banner-trial {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--font-size-base);
  line-height: 1.4;
  color: var(--bs-white);
  gap: var(--padding-base);
  padding-top: var(--padding-xs);
  padding-bottom: var(--padding-xs);

  @include media-breakpoint-up(md) {
    font-size: var(--font-size-lg);
  }

  &.bg-danger {
    background-color: var(--builder-banner-bg-danger) !important;
  }
  &.bg-importing {
    background-color: var(--builder-banner-bg-importing) !important;
    color: var(--builder-banner-color-importing);
  }
}

.discount-banner {
  background-color: var(--marketing-green-lime) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 54px;
}

.importing-books-container {
  margin-top: var(--padding-xl2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: var(--padding-sm);
  color: var(--builder-banner-color-importing);
  p {
    margin: 0;
  }
  .importing-text {
    font-weight: 500;
    font-size: var(--font-size-base);
    line-height: var(--font-size-xl);
  }
  .importing-info {
    font-weight: 400;
    font-size: var(--font-size-sm);
    line-height: var(--font-size-md);
  }
}
.landing-login-page {
  max-width: 33.75rem;
}

.choose-themePanel {
  overflow: hidden;
  background-color: var(--builder-previewpanel-background);

  .chooseThemeAppHeader {
    padding-inline: 0;
    padding-block: 0;
    background-color: var(--bs-white);

    .showOnMobile {
      display: none;
      @include media-breakpoint-down(xl) {
        display: block;
      }
    }
    .hide {
      display: none;
    }
    .hideOnTablet {
      @include media-breakpoint-down(xl) {
        display: none;
      }
    }

    .buttonLeft {
      border: none;
      background: var(--tfa-color-text);
    }
  }

  .chooseThemeDashboard {
    width: 100%;
    height: calc(
      100% - var(--builder-onboarding-header-sm) - var(--builder-onboarding-footer-sm)
    );
    overflow: auto;

    @include media-breakpoint-up(lg) {
      height: calc(
        100% - var(--builder-onboarding-header-lg) - var(--builder-onboarding-footer-lg)
      );
    }

    .panelInner {
      padding-block: 0;
      min-height: 100%;
    }

    .builderForm {
      flex-direction: column;
      justify-content: space-between;
      padding-block: var(--padding-xl);
      min-height: 100%;
      max-width: none;
    }

    .themeField {
      margin-bottom: 0;
      padding-bottom: var(--padding-xl);
    }

    .layout-option {
      .card-footer {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .chooseThemePanel {
    width: 100%;
    height: calc(
      100% - var(--builder-onboarding-header-sm) - var(--builder-onboarding-footer-sm)
    );
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1 1;

    @include media-breakpoint-up(sm) {
      height: 100%;
    }

    .previewPanel {
      a,
      .previewUrlBar,
      .panelTitle {
        display: none;
      }

      .goToSiteBar {
        justify-content: center;
      }

      &.scaledDown {
        max-width: 390px;
        iframe {
          width: 768px;
        }
      }

      @include media-breakpoint-up(md) {
        &.scaledDown {
          width: 475px;
          iframe {
            width: 1280px;
          }
        }
      }

      &.previewMobile {
        max-width: 390px;
        &.scaledDown {
          iframe {
            width: 768px;
          }
        }
      }

      &.previewDesktop {
        @include media-breakpoint-up(xl) {
          width: 768px;
          &.scaledDown {
            width: 475px;
            iframe {
              width: 768px;
            }
          }
        }
      }
    }
  }

  .chooseThemeAppInner {
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
    padding-inline: 0;
    padding-block: 0;

    &.step0 {
      overflow-y: scroll;
    }

    &.step1 {
      height: calc(
        100% - var(--builder-onboarding-header-sm) - var(--builder-onboarding-footer-sm)
      );
    }

    &::after {
      content: '';
      display: block;
      height: 10rem;
    }

    .hideInApp {
      display: none;
    }

    .asideWrapper {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      flex: 1;

      @include media-breakpoint-up(lg) {
        height: calc(
          100vh - var(--builder-onboarding-header-lg) - var(
              --builder-onboarding-footer-lg
            )
        );
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }

    .formActions {
      position: fixed;
      margin-top: 0;
      bottom: 0;
      left: 0;
      padding-block: 0;
      padding-inline: var(--padding-base);
      width: 100%;
      min-height: var(--builder-onboarding-footer-sm);
      background: var(--bs-white);
      z-index: 100;

      @include media-breakpoint-up(lg) {
        min-height: var(--builder-onboarding-footer-lg);
      }

      .btn {
        padding-inline: var(--padding-lg);
      }

      @include media-breakpoint-up(xl) {
        padding-inline: var(--padding-xl);

        .btn {
          font-size: var(--font-size-xl2);
        }
      }
    }

    .aside {
      position: relative;
      width: 100%;
      min-width: 20rem;
      min-height: fit-content;
      background-color: var(--builder-previewpanel-background);

      @include media-breakpoint-up(md) {
        &:not(.expanded) {
          width: var(--builder-onboarding-sidebar-width);
        }
      }

      .formField {
        .form-label {
          color: var(--grays-gray-07-666666);
          font-size: var(--font-size-sm);
          text-transform: uppercase;
          letter-spacing: -0.01625rem;
        }
      }

      .fieldHeadline {
        margin-bottom: var(--padding-lg);

        .form-label {
          color: var(--bs-black);
          font-size: var(--font-size-xl2);
          text-transform: none;
        }
      }

      .fieldColorPalette {
        margin-bottom: var(--padding-sm);

        .paletteOptions {
          margin-bottom: 0;
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          margin-inline-start: calc(var(--padding-xs3) * -1);
          padding: var(--padding-xs3);
          padding-bottom: var(--padding-base);

          @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
          }

          .layout-option {
            flex-shrink: 0;
          }
        }
      }

      .fieldRadio {
        margin-bottom: 0;
      }
      .form-check {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-inline: var(--padding-sm);
        border-radius: 0.5rem;
        border: var(--builder-input-border-color);
        border-width: 1px;
        border-style: solid;

        &:has(input:checked) {
          border-color: var(--bs-primary);
        }
        input {
          margin-left: 0rem;
        }
        .form-check-input {
          margin-top: 0;
        }
        label {
          flex: 1;
          margin-left: 0.5rem;
          padding-block: 0.8rem;
        }
      }

      &.expanded {
        border: none;
      }
    }

    .previewFrame {
      overflow: hidden;
    }

    .previewPanelTools {
      display: none;
      @include media-breakpoint-up(md) {
        display: flex;
      }
    }

    .hide {
      display: none;
    }

    @include media-breakpoint-down(xl) {
      .hideOnTablet {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      .hideOnMobile {
        display: none;
      }
    }
  }
}

.choose-theme_undefined {
  .appInner {
    overflow: hidden;
  }
}

.marketing {
  &.marketing-welcome {
    .navbar-collapse {
      display: none !important;
    }
    header {
      .mx-sm-auto {
        margin-inline: auto;
      }
    }
  }

  .welcome-container {
    margin-top: var(--padding-xl);
    @include media-breakpoint-up(md) {
      margin-top: var(--padding-xl2);
    }
    .formInner {
      margin-top: 5rem;

      @include media-breakpoint-up(md) {
        margin-top: 6.25rem;
      }
      .form-input {
        display: flex;
        flex-direction: column;
        gap: var(--padding-base);
        @include media-breakpoint-up(lg) {
          flex-direction: row;
        }
        button {
          height: 3.75rem;
          min-width: 10rem;
        }
        .form-floating {
          flex: 1;
        }
      }
      max-width: 34rem;

      .getStartedImage {
        right: -2rem;
        top: -3rem;
        width: 18rem;
        @include media-breakpoint-up(lg) {
          right: -12rem;
          top: -4rem;
        }
      }
    }
    .instantWebsites {
      .sectionBody {
        width: auto;
        font-size: var(--font-size-lg);
        line-height: var(--font-size-xl3);
        @include media-breakpoint-up(md) {
          font-size: var(--font-size-xl);
          line-height: var(--padding-xl1);
          text-align: center;
        }
      }
    }
  }
}

.success-send {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  @include media-breakpoint-up(md) {
    margin-top: 4rem;
  }
  .sectionTitle {
    margin-bottom: var(--padding-base);
    font-size: var(--font-size-xl4);
    font-family: var(--font-itc-clear-face-regular);
    text-align: center;
    line-height: 110%;
    letter-spacing: -0.05rem;
    @include media-breakpoint-up(md) {
      font-size: 3.75rem;
      letter-spacing: -0.09rem;
    }
  }
  .sectionSubtitle {
    font-size: 1.625rem;
    font-weight: 400;
    line-height: 140%;
  }
}

.edit-mailchimp-syncPanel,
.set-up-mailchimp-syncPanel {
  h1 {
    font-size: var(--font-size-xl3);
    margin-bottom: var(--padding-xl);
  }
  .panel {
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(sm) {
      padding-left: var(--padding-lg);
      padding-right: var(--padding-lg);
    }
  }
}

.custom-checkbox {
  padding-left: 0;
  label {
    display: flex;
    align-items: center;
  }
  .bottom-text {
    padding-left: 2.2rem !important;
  }
}

.custom-checkbox input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  padding-left: 0;
}

.custom-checkbox .bi {
  font-size: var(--font-size-xl2);
  margin-right: var(--padding-xs);
  transition: color 0.3s ease-in-out;
  padding-left: 0;
}

.custom-form-input .form-control {
  font-size: var(--font-size-base);
}

.input-control {
  position: relative;
  input:disabled {
    background-color: var(--bs-light);
  }
}

.lock-icon {
  position: absolute;
  right: var(--padding-sm);
  top: 50%;
  transform: translateY(-50%);
  color: var(--grays-gray-07-666666);
}

.navbar-brand img {
  width: 11rem;
  height: var(--padding-lg);
  @include media-breakpoint-up(sm) {
    width: 16rem;
    height: 2.25rem;
  }
}

.social-postsPanel {
  & .container-fluid {
    & > div {
      margin-bottom: var(--padding-base);
      h1 {
        font-size: var(--font-size-xl3);
        line-height: var(--font-size-xl3);
        margin-bottom: 0;
      }
    }
    & > h3 {
      font-size: var(--font-size-xl2);
      font-weight: 600;
      line-height: var(--font-size-xl2);
    }
  }
  .avatar-user {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: var(--bs-border-radius-pill);
  }
  .panel {
    max-width: 75rem;
  }
  .info {
    font-weight: 400;
    & strong {
      font-weight: 600;
    }
  }
  .container-brand {
    h4 {
      font-size: var(--font-size-base);
      font-weight: 600;
      line-height: var(--font-size-xl2);
      margin-bottom: var(--padding-base);
    }
  }
  .card-custom {
    width: 100%;
    text-align: left;
    background: var(--bs-light);
    height: 8.75rem;
    border-width: var(--padding-xs2);
    border-color: var(--builder-color-bg-button-secondary);
    .card-body {
      display: flex;
      gap: var(--padding-base);
      align-items: center !important;
      padding: var(--padding-xl) var(--padding-md);
      .container-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--padding-xl4);
        height: var(--padding-xl4);
        background: var(--bs-white);
        border-radius: var(--bs-border-radius-sm);
        .circle {
          width: 1.75rem;
          height: 1.75rem;
          border-radius: 50%;
          position: absolute;
          background: #f8f9fa;
          &:first-child {
            left: var(--padding-base);
          }
          &:last-child {
            right: var(--padding-base);
          }
        }
      }
      .caption {
        font-size: var(--font-size-xs);
        font-weight: 500;
      }
    }
  }
  .suggested {
    font-size: var(--font-size-md);
    font-weight: 400;
    line-height: var(--font-size-xl);
    span:first-of-type {
      color: #4f4f4f;
      font-size: var(--font-size-xs);
      font-weight: 500;
    }
  }
  .style-guide {
    font-size: var(--font-size-xs);
    font-weight: 400;
    line-height: var(--font-size-base);
  }

  .post-card {
    h4 {
      font-size: var(--font-size-md);
      font-weight: 600;
    }
    p {
      font-size: var(--font-size-base);
      line-height: var(--font-size-xl);
    }
    .card {
      --bs-card-border-color: rgba(242, 242, 242, 1);
      .card-text {
        font-size: var(--font-size-base);
        line-height: var(--font-size-xl);
      }
      .card-image {
        cursor: pointer;
        position: relative;
        img {
          position: relative;
          width: 100%;
          height: auto;
          z-index: 20;
        }
      }
    }
  }
}

.site-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  h1,
  h2 {
    font-family: ITC Clearface Std;
    font-size: var(--font-size-xl7);
    font-weight: 400;
    line-height: var(--font-size-xl7);
    @include media-breakpoint-up(md) {
      font-size: var(--font-size-xl10);
      line-height: var(--font-size-xl10);
      &.no-found {
        font-size: var(--font-size-xl8);
        line-height: var(--font-size-xl8);
      }
    }
  }
  p {
    font-size: var(--font-size-lg);
    font-weight: 400;
    line-height: var(--font-size-xl4);
    text-align: center;
  }
}

.cards-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: var(--padding-xl2);
  padding-bottom: var(--padding-xl2);
  max-width: 500px;

  .title {
    font-size: var(--font-size-xl3);
    font-weight: 600;
    letter-spacing: -0.1%;
  }

  .subtitle {
    font-size: var(--font-size-base);
    font-weight: 500;
  }

  .card {
    cursor: pointer;
    width: 100%;
    margin-top: var(--padding-xs);
    padding-left: var(--padding-xs);
    padding-right: var(--padding-xs);
    padding-top: var(--padding-lg);
    padding-bottom: var(--padding-base);
  }
}
.email-dns-configPanel {

  font-family: Inter;
  h1 {
    font-weight: 600;
    font-size: var(--font-size-xl3);
    line-height: var(--font-size-xl6);
  }
  .component-title {
    font-weight: 600;
    font-size: var(--font-size-lg);
    line-height: var(--font-size-lg);
  }
  .builderForm {
    .formField {
      margin-bottom: var(--padding-lg);
    }
    .formField.fieldRow.fieldText {
      @include media-breakpoint-up(md) {
        & > div:first-of-type {
          width: 16.66666667% !important;
        }
      }
    }
  }
}
@import 'marketing';
@import 'websites';

.carousel-testimonials {
  background-color: #fafafa;
  margin-bottom: var(--padding-xl3);
  padding: var(--padding-lg);
  padding-top: var(--padding-xl2);
  border-width: 2px 0;
  border-style: solid;
  border-color: var(--marketing-border-color);
  @include media-breakpoint-up(md) {
    padding: var(--padding-xl2);
  }
  .carousel-indicators {
    top: 100% !important;
    bottom: 0 !important;
    .active {
      background-color: var(--bs-black);
    }
  }
  h3 {
    font-weight: 600;
    font-size: var(--font-size-base);
    line-height: var(--font-size-base);
    text-align: center;
    margin-bottom: var(--padding-lg);

    @include media-breakpoint-up(md) {
      font-size: var(--font-size-xl3);
      line-height: var(--font-size-xl2);
      margin-bottom: var(--padding-xl);
    }
  }
  .carousel {
    margin-bottom: var(--padding-base);
    @include media-breakpoint-up(md) {
      margin-bottom: var(--padding-xl);
    }
  }
  .carousel-item {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      min-height: auto !important;
      margin-top: 0 !important;
      width: 90px;
      height: 90px;
      @include media-breakpoint-up(md) {
        width: 105px;
        height: 105px;
      }
    }
    p {
      max-width: 79rem;
      &:first-of-type {
        font-weight: 400;
        font-size: var(--font-size-sm);
        line-height: var(--font-size-xl);
        margin-top: var(--padding-base);

        @include media-breakpoint-up(md) {
          font-size: var(--font-size-xl3);
          line-height: var(--font-size-xl6);
        }
      }
      &:last-child {
        font-weight: 400;
        font-size: var(--font-size-base);
        line-height: var(--font-size-xl2);

        @include media-breakpoint-up(md) {
          font-size: var(--font-size-xl6);
          line-height: var(--font-size-xl6-5);
        }
      }
    }
    &:not(.active) {
      opacity: 0 !important;
    }
  }
}

.profile-dropdown-toggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #f5f5f5;
  border: none;
  color: var(--bs-dark);
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  &:hover {
    background-color: #e5e5e5;
  }

  .text-truncate {
    flex: 1;
    min-width: 0; // This is important for text truncation to work
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: 600;
  }

  .overflow-hidden {
    flex-shrink: 0;
  }

  svg {
    flex-shrink: 0;
  }
}
.edit-mailerlite-syncScreen {
  h1 {
    font-weight: 600;
    font-size: var(--font-size-xl3);
    line-height: 100%;
  }
  .input-token {
    border: none;
    background-color: var(--grays-gray-00-f9f9f9);
    .card-title {
      font-weight: 600;
      font-size: var(--font-size-base);
      line-height: 100%;
    }
    .masked-input {
      letter-spacing: 2px;
    }
  }
  .instruction {
    & > * {
      font-size: var(--font-size-base);
      line-height: var(--font-size-xl2);
    }
    h3 {
      font-weight: 500;
    }
  }
}
